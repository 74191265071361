import React, {useRef} from 'react';
import {logger} from '@utils/logger';

type ImageProps = {
  src: string | undefined;
  alt: string;
  className: string;
  onErrorImage?: string;
  testid: string;
};
const ImageByTest = ({testid, src = '/nofile.gif', alt, className, onErrorImage}: ImageProps) => {
  const imageElem = useRef<HTMLImageElement>(null);

  return (
    <img
      data-testid={testid}
      src={src}
      alt={alt}
      className={className}
      onError={() => {
        logger('Bad Image')(src);
        imageElem!.current!.src = onErrorImage || '/images/box.svg';
      }}
      ref={imageElem}
    />
  );
};
export default ImageByTest;
