import {get, post, destroy} from '@integrations/axios';

const projectsAPIs = {
  getAllProjects: get('rails', '/api/techs/projects'),
  getProjectById: get('rails', '/api/techs/projects/::pid'),
  addTechToProject: post('rails', 'api/techs/projects/::pid/techs'),
  removeTechFromProject: destroy('rails', '/api/techs/projects/::pid/techs/::tech_id'),
  getAllTechsInProject: get('rails', 'api/techs/projects/::pid/techs'),
  search: get('rails', '/api/techs/projects/techs?search=::term'),
  getPaymentDetails: get('rails', '/api/techs/projects/::pid/payments'),
};

export default projectsAPIs;
