import React from 'react';
import {SwipeableList, SwipeableListItem, Type as SwipeableListType} from 'react-swipeable-list';

/* Utils & Constants */
import {pluralize} from '@utils/formatter/pluralize';

/* Components */
import {CardJob} from '@components/UI/Card';
import JobStatusDisplay from '@features/Jobs/Parts/Jobs.status';
import EmptyResults from '@components/UI/Empty';

/* Styles */
import 'react-swipeable-list/dist/styles.css';

/* Types */
import {SearchComponentProps, SearchTypes} from '@features/Jobs/jobs.types';

const SearchStatusComponent = ({searchType, loadedUnits, handleListItemClick, swipeLeftActions, swipeRightActions, handleSwipeProgress}: SearchComponentProps) => {
  const {jobs = [], pagination = {}} = loadedUnits;

  /* User is in status filtering view */
  if (searchType === SearchTypes.status) {
    if (pagination && pagination.current_page && !pagination.total_units) {
      return <EmptyResults icon="Error" title="Filter produced no results" />;
    }
  }

  /* User is in keyword search view */
  if (searchType === SearchTypes.keyword) {
    if (pagination && !pagination.current_page) {
      return <EmptyResults icon="Search" title="Search for a job by name or service" />;
    }
    if (pagination && pagination.current_page && !pagination.total_units) {
      return <EmptyResults icon="Search" title="No Results" />;
    }
  }

  return (
    <>
      <SwipeableList type={SwipeableListType.IOS}>
        {jobs.map(unit => (
          <SwipeableListItem
            maxSwipe={1}
            leadingActions={swipeRightActions(unit)()}
            trailingActions={swipeLeftActions(unit)()}
            key={`${unit.id}-${unit.unit_name}`}
            onClick={handleListItemClick(unit.id)}
            className={`marginBottom-tiny1 swipeable-list-${unit.status}`}
            // @ts-ignore
            onSwipeProgress={handleSwipeProgress(unit.id)}
          >
            <CardJob RightComponent={JobStatusDisplay(unit)} name={unit.unit_name || '--'} info={pluralize({text: 'Service', count: unit.service_count})} className="full-width" />
          </SwipeableListItem>
        ))}
      </SwipeableList>
    </>
  );
};
export default SearchStatusComponent;
