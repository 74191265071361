import {post, get, put, destroy} from '@integrations/axios';

const jobAPIs = {
  listJobs: get('rails', '/api/techs/projects/::project_id/units'),
  startJob: post('rails', '/api/techs/projects/::project_id/units/start'),
  createJob: post('rails', '/api/techs/projects/::project_id/units/create'),
  updateJob: put('rails', '/api/techs/projects/::project_id/units/::unit_id'),
  cancelJob: post('rails', '/api/techs/projects/::project_id/units/::unit_id/cancel'),
  unserviceJob: post('rails', '/api/techs/projects/::project_id/units/::unit_id/unserviceable'),
  approveJob: post('rails', '/api/techs/projects/::project_id/units/::unit_id/approve'),
  getCancelReasons: get('rails', '/api/techs/projects/::project_id/units/::unit_id/reasons'),
  search: post('rails', '/api/techs/projects/::project_id/units'),
  searchCount: post('rails', '/api/techs/projects/::project_id/units/count'),
  getJobDetails: get('rails', '/api/techs/projects/::project_id/units/::unit_id'),
  reopenJob: post('rails', '/api/techs/projects/::project_id/units/::unit_id/reopen'),
  getJobLogNotes: get('rails', '/api/techs/projects/::project_id/units/::unit_id/notes'),
  createJobLogNote: post('rails', '/api/techs/projects/::project_id/units/::unit_id/notes'),
  deleteJob: destroy('rails', '/api/techs/projects/::project_id/units/::unit_id'),
};

export default jobAPIs;
