import {Children} from '@features/Application/application.types';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import React from 'react';

type MenuLineItemProps = {
  children: Children;
  className?: string;
  icon?: string;
  dataTestId?: string;
  onClick?: BaseAnyFunction;
};
const MenuLI = ({children, className, icon, dataTestId = '', onClick}: MenuLineItemProps) => (
  <li data-testid={`${dataTestId}-li`} role="presentation" className={cn('p0 paddingY-small paddingRight-medium2 paddingLeft-small', className)} onClick={onClick}>
    {icon && <Icon dataTestId={`${dataTestId}-icon`} className="paddingRight-small icon-large" name={icon} />}
    {children}
  </li>
);

export default MenuLI;
