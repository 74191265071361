import React, {useState, useRef} from 'react';
import cn from 'classnames';
import {useRouter} from 'next/router';
// Hooks
import useOutsideClick from '@hooks/useOutsideClick';
// Utils
import {formatUnitStatus} from '@features/Jobs/jobs.utils';
import {updateRouteByModalRequest} from '@utils/route';
// Paths
import {createNewServicePath} from '@constants/constants.paths';
// Types
import {StatusesJob, UnitWithServices} from '@features/Jobs/jobs.types';
import {ModalNames} from '@features/Projects/projects.types';
// Components & Styles
import TouchTarget from '@components/UI/TouchTarget';
import {Icon} from 'ht-styleguide';
import {MenuListItem, MenuListItemCalloutGroup, MenuPanel, MenuUnorderedList} from '@components/UI/Menu';
import Permission from '@components/Permission';

/* Styles */
import styles from './jobDetailHeader.module.scss';

/* Test data ids */
export const backArrowTestId = 'JobDetailHeader-BackArrow';
export const jobLogIconTestId = 'JobDetailHeader-JobLogIcon';
export const openMenuIconTestId = 'JobDetailHeader-OpenMenuIcon';

type JobDetailHeaderProps = {
  onBackClick: () => void;
  onJobLogClick: () => void;
  unit: UnitWithServices;
  jobId: string;
};

const JobDetailHeader = ({onBackClick, onJobLogClick, unit, jobId}: JobDetailHeaderProps) => {
  const router = useRouter();
  const {
    push,
    query: {jid, pid},
  } = router;
  const [showEditMenu, setShowEditMenu] = useState<boolean>(false);
  const toggleEditMenu = () => setShowEditMenu(!showEditMenu);
  const editMenuRef: React.RefObject<HTMLDivElement> = useRef(null);

  useOutsideClick(editMenuRef, () => (showEditMenu ? toggleEditMenu() : null), [showEditMenu]);

  const goToCreateServicePage = () => push(createNewServicePath(pid, jid));

  if (!unit) return null;

  const unitIsCompleted = unit.status === StatusesJob.completed;
  const unitName = unit.unit_name || '--';
  const textStatus = cn('blue700', {
    [`${unit.status}_text`]: true,
  });

  return (
    <>
      <header className={cn('paddingY-small', styles.container)}>
        <div className="marginBottom-small flex justifyContentSpaceBetween alignItemsCenter position relative">
          <TouchTarget onClick={onBackClick}>
            <Icon name="Arrow" className={styles.icon} dataTestId={backArrowTestId} />
          </TouchTarget>
          <div className="display flex">
            <TouchTarget onClick={onJobLogClick} className="marginRight-medium">
              <Icon name="Clipboard" className={styles.icon} dataTestId={jobLogIconTestId} />
            </TouchTarget>
            <TouchTarget onClick={toggleEditMenu}>
              <div ref={editMenuRef}>
                <Icon name="More" className={styles.icon} dataTestId={openMenuIconTestId} />
              </div>
            </TouchTarget>
          </div>
        </div>
        <h4 className="marginBottom-tiny1 n900">{unitName}</h4>
        <div className="flex justifyContentSpaceBetween alignItemsCenter">
          <ul className="caption n700 display flex">
            <li>Job #{jobId}</li>
          </ul>
          <div className={cn('caption', styles.status_caption)}>
            <span className={textStatus}>{formatUnitStatus(unit.status)}</span>
          </div>
        </div>
      </header>
      {showEditMenu && (
        <MenuPanel>
          <MenuUnorderedList>
            <MenuListItem dataTestId="menu-edit-job-info" icon="Edit" onClick={() => updateRouteByModalRequest(ModalNames.UPDATE_JOB)}>
              Edit Job Info
            </MenuListItem>
            <MenuListItem dataTestId="menu-nocomplete-job" icon="Unserviceable" onClick={() => updateRouteByModalRequest(ModalNames.NO_COMPLETE)}>
              Cannot Complete
            </MenuListItem>
            <MenuListItem dataTestId="menu-addservice-job" icon="Plus-Circle" onClick={goToCreateServicePage}>
              Add a Service
            </MenuListItem>
            {!unitIsCompleted && (
              <Permission>
                <MenuListItemCalloutGroup>
                  <MenuListItem dataTestId="menu-delete-job" icon="Trash" onClick={() => updateRouteByModalRequest(ModalNames.DELETE_JOB)}>
                    Delete Job
                  </MenuListItem>
                </MenuListItemCalloutGroup>
              </Permission>
            )}
          </MenuUnorderedList>
        </MenuPanel>
      )}
    </>
  );
};

export default JobDetailHeader;
