import { isBrowser } from '@utils/env';

const PREFIX = '@ht_';

const createKey = (key: string) => `${PREFIX}${key}`;

export const TOKEN = createKey('token');
export const EMAIL = createKey('email');

/* Auth Token helper to make it clear what we are doing */
export const authToken = (() => {
  const {localStorage} = (isBrowser() ? window : {}) as Storage;
  const operations = ({type, arg, name}: {type: string, arg?: string, name: string}) => {
    if (!isBrowser()) return "";

    return localStorage[`${type}Item`](name, arg);
  };

  return {
    getAuthToken: () => operations({type: 'get', name: TOKEN}),
    removeAuthToken: () => operations({type: 'remove', name: TOKEN}),
    setAuthToken: (arg: string) => operations({type: 'set', name: TOKEN, arg }),
    getAuthTokenId: () => authToken.isValidJWT(true),
    isValidJWT: (raw = false): boolean | number => {
      const token = authToken.getAuthToken();

      try {
        const {id, email}: {id: number; email: string} = JSON.parse(atob(token.split('.')[1]));
        if (raw) {
          return id;
        }
        return Boolean(id || email);
      } catch (e) {
        return false;
      }
    },
  };
})();
