import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import {getAuthLayout} from '@components/Layouts/Layout.auth';
import {LeadingActions, TrailingActions} from 'react-swipeable-list';
/* Store */
import {useAppDispatch, useAppSelector} from '@store/store';
import {projectsDuck} from '@features/Projects/Projects.ducks';
import {jobsDuck} from '@features/Jobs/Jobs.ducks';
/* Components */
import HeaderDetails from '@features/Projects/Parts/Header/Header.details';
import SearchResultsComponent from '@features/Projects/Parts/Project.searchresults';
import SwipeButton, {SwipeButtonTypes} from '@components/UI/SwipeButton';
import FloatingActionButton from '@components/UI/FloatingButton';
import Permission from '@components/Permission';
import GridMdu from '@components/UI/GridMdu';
/* Cookie */
import {getProjectTechPromptModalCookie} from '@features/Projects/projects.cookie';
/* Constants/Utils */
import {jobPath} from '@constants/constants.paths';
import {updateRouteByModalRequest} from '@utils/route';
/* Types */
import {ModalNames, MDUStatuses} from '@features/Projects/projects.types';
import {Pagination, SearchTypes, StatusesJob, Unit} from '@features/Jobs/jobs.types';
import {INIT_TYPES} from '@features/Jobs/Modals/Jobs.create';
/* Styles */
import styles from './projects.module.scss';

/* Dynamic Component Imports */
const TeamListModalDynamic = dynamic(() => import('@features/Projects/Modals/Modal.teamlist'), {ssr: false});
const AddTeamModalDynamic = dynamic(() => import('@features/Projects/Modals/Modal.teamadd'), {ssr: false});
const TeamRemoveModalDynamic = dynamic(() => import('@features/Projects/Modals/Modal.teamremove'), {ssr: false});
const TeamPromptModalDynamic = dynamic(() => import('@features/Projects/Modals/Modal.teamprompt'), {ssr: false});
const ProjectInfoModalDynamic = dynamic(() => import('@features/Projects/Modals/Modal.projectinfo'), {ssr: false});
const FilterModalDynamic = dynamic(() => import('@features/Projects/Modals/Modal.filter'), {ssr: false});
const CreateModalDynamic = dynamic(() => import('@features/Jobs/Modals/Jobs.create'), {ssr: false});
const CannotCompleteModalDynamic = dynamic(() => import('@features/Jobs/Modals/Jobs.nocomplete'), {ssr: false});
const PaymentDetailsModalDynamic = dynamic(() => import('@features/Projects/Modals/Modal.paymentdetails'), {ssr: false});

const ProjectsDetailsPage = () => {
  /* Hooks */
  const {
    push,
    query: {pid, listteam, addteam, removetech, projectinfo, filter, startjob, createjob, nocomplete, paymentdetails},
  } = useRouter();
  const dispatch = useAppDispatch();
  const loadedUnits = useAppSelector(jobsDuck.selectors.getSearchResults);
  const project = useAppSelector(projectsDuck.selectors.getCurrentProject(pid as string));
  const searchType = useAppSelector(jobsDuck.selectors.getSearchType);
  const searchResults = useAppSelector(jobsDuck.selectors.getSearchResults);

  /* Local State */
  const [openSwipedItems, setOpenSwipedItems] = useState<number[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<Unit>();

  /* Constants */
  const hasPromptCookie = getProjectTechPromptModalCookie(pid as string);
  const isApprovedOrNeedsApproval = [MDUStatuses.APPROVED, MDUStatuses.NEEDS_APPROVAL].includes(project?.status as MDUStatuses);
  const showCreateTeamModal = isApprovedOrNeedsApproval ? false : !hasPromptCookie;

  /* Ref */
  const resultPagination = useRef<Pagination>({});
  const paginationInEffect = useRef(false);

  /* Methods */
  const addToOpenSwipedItems = (jobId: number) => {
    if (!openSwipedItems.includes(jobId)) setOpenSwipedItems([...openSwipedItems, jobId]);
  };
  const removeFromOpenSwipedItems = (jobId: number) => {
    setOpenSwipedItems(openSwipedItems.filter(id => id !== jobId));
  };

  const onHandleSwipeUnitRight = (unit: Unit) => {
    setSelectedUnit(unit);
    dispatch(jobsDuck.actions.approveJob({jid: unit.id}));
  };

  const onHandleSwipeUnitLeft = (unit: Unit, type: SwipeButtonTypes) => {
    // Either reopen the job, or bring up the cancel modal
    setSelectedUnit(unit);
    switch (type) {
      case SwipeButtonTypes.UNCANCEL:
        dispatch(jobsDuck.actions.reopenJob({id: unit.id}));
        break;
      case SwipeButtonTypes.ALERT:
        dispatch(jobsDuck.actions.deleteJob({unit_id: unit.id, unit_name: unit.unit_name}));
        break;
      default:
        updateRouteByModalRequest(ModalNames.NO_COMPLETE);
    }
  };

  /**
   * Swipe right needs to two conditions:
   * 1. unit needs to be in a pending approval
   * 2. User is tech-lead
   * @param {Unit} unit
   * @returns {() => (null | any)}
   */
  const swipeRightActions = (unit: Unit) => () => {
    if (unit.status !== StatusesJob.pending_approval) {
      return null;
    }

    return (
      <Permission>
        <LeadingActions>
          <SwipeButton status={unit.status} type={SwipeButtonTypes.SUCCESS} className="marginRight-tiny1" onButtonClick={() => onHandleSwipeUnitRight(unit)} />
        </LeadingActions>
      </Permission>
    );
  };

  const swipeLeftActions = (unit: Unit) => () => {
    if (!unit.unit_name || unit.status === StatusesJob.completed) return null;

    return (
      <TrailingActions>
        <Permission>
          <SwipeButton status={unit.status} type={SwipeButtonTypes.ALERT} className="marginLeft-tiny1" onButtonClick={() => onHandleSwipeUnitLeft(unit, SwipeButtonTypes.ALERT)} />
        </Permission>
        {[StatusesJob.cancelled, StatusesJob.unserviceable].includes(unit.status) ? (
          <SwipeButton status={unit.status} type={SwipeButtonTypes.UNCANCEL} onButtonClick={() => onHandleSwipeUnitLeft(unit, SwipeButtonTypes.UNCANCEL)} />
        ) : (
          <SwipeButton status={unit.status} type={SwipeButtonTypes.CANCEL} onButtonClick={() => onHandleSwipeUnitLeft(unit, SwipeButtonTypes.CANCEL)} />
        )}
      </TrailingActions>
    );
  };

  const handleSwipeProgress = (unitId: number) => (progress: number) => {
    /*
      The entire list item is clickable, and the exposed items have their own onClick functions.
      Block the list item's onClick function if it is swiped open
    */
    const swipeIsActiveThreshold = 15;
    const isSwipedOpen = progress > swipeIsActiveThreshold;
    return isSwipedOpen ? addToOpenSwipedItems(unitId) : removeFromOpenSwipedItems(unitId);
  };

  const handleListItemClick = (unitId: number) => () => {
    if (openSwipedItems.includes(unitId)) return;
    push(jobPath(pid, unitId));
  };
  /*
   * Search for Status Filters
   * This is an independent "search"
   * Hand back from Filter modal to determine search.
   *
   * note: If we have a saved filter, don't refetch. Use whats in cache.
   */
  const onHandleSearchAPI = useCallback(async () => {
    if (!searchResults?.jobs.length) {
      await dispatch(jobsDuck.actions.searchCount(null));
      await dispatch(jobsDuck.actions.search());
    }
  }, [dispatch]);

  /*
   * Given current filter parameters, we initiate a paginated search within
   * those confines. ie. person scrolling to bottom.
   */
  const onHandlePaginationSearchAPI = useCallback(async () => {
    paginationInEffect.current = true;
    await dispatch(jobsDuck.actions.paginationSearch());
    paginationInEffect.current = false;
  }, [dispatch]);

  /* Refs are used because they are unaffected by the closure created */
  const onScrollHandler = useCallback(() => {
    const pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    if (window.innerHeight + window.scrollY >= pageHeight - 10) {
      if (resultPagination.current.has_more && !paginationInEffect.current) {
        onHandlePaginationSearchAPI();
      }
    }
  }, [onHandlePaginationSearchAPI]);

  /* Effects */
  useEffect(() => {
    resultPagination.current = loadedUnits.pagination;
  }, [loadedUnits]);

  useEffect(() => {
    window?.addEventListener('scroll', onScrollHandler);

    return () => {
      window?.removeEventListener('scroll', onScrollHandler);
    };
  }, [dispatch, onScrollHandler]);

  /*
    Initial Page load.

    Note: We get a "new project" load everytime. We need to decide if this feasible or not.
          Why? It could create sync issues because we are "remembering" search results,
               but asking for "new" project. There could be an added job in the meantime.
               Edge case, but we can't ignore it, if its possible, right?

               If we do not want "reload" just check against current "pid" and action "getCurrentProject".
               If same, and it "getCurrentProject" exists. Leave it.
  */
  useEffect(() => {
    if (pid) {
      (async () => {
        await Promise.all([dispatch(projectsDuck.actions.getProjectById()), onHandleSearchAPI()]);
      })();
    }
  }, [pid, dispatch, onHandleSearchAPI]);

  /* When on a project, clear the job/unit. */
  useEffect(() => {
    dispatch(jobsDuck.actions.clearJob());
  }, []);

  return (
    <div className={styles.page}>
      <HeaderDetails project={project} />
      <GridMdu.Fluid>
        <div className={cn(styles.container, 'paddingtop')}>
          <div className="paddingBottom-huge">
            <SearchResultsComponent
              handleListItemClick={handleListItemClick}
              swipeLeftActions={swipeLeftActions}
              swipeRightActions={swipeRightActions}
              handleSwipeProgress={handleSwipeProgress}
              loadedUnits={loadedUnits}
              searchType={searchType}
            />
          </div>
          <FloatingActionButton buttonAction={() => updateRouteByModalRequest(ModalNames.START_JOB)} suppress={searchType === SearchTypes.keyword} title="Start a Job" />
          <style jsx>{`
            .paddingtop {
              padding-top: 200px;
            }
          `}</style>
        </div>
      </GridMdu.Fluid>
      {/* Modals */}
      {listteam && <TeamListModalDynamic isVisible={Boolean(listteam)} />}
      {addteam && <AddTeamModalDynamic isVisible={Boolean(addteam)} />}
      {removetech && <TeamRemoveModalDynamic isVisible={Boolean(removetech)} />}
      {project?.id && showCreateTeamModal && <TeamPromptModalDynamic isVisible={showCreateTeamModal} />}
      {projectinfo && <ProjectInfoModalDynamic project={project} isVisible={Boolean(projectinfo)} />}
      {filter && <FilterModalDynamic isVisible={Boolean(filter)} />}
      {startjob && <CreateModalDynamic type={INIT_TYPES.start} project={project} isVisible={Boolean(startjob)} />}
      {createjob && <CreateModalDynamic type={INIT_TYPES.create} project={project} isVisible={Boolean(createjob)} />}
      {nocomplete && <CannotCompleteModalDynamic unit={selectedUnit} isVisible={Boolean(nocomplete)} />}
      {paymentdetails && <PaymentDetailsModalDynamic isVisible={Boolean(paymentdetails)} project={project} />}
    </div>
  );
};

ProjectsDetailsPage.Layout = getAuthLayout();

export default ProjectsDetailsPage;
