import React from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import {ServiceImage, StatusesService} from '@features/Services/services.types';
import ImageByTest from '@components/UI/Image';

import styles from './card.module.scss';

type CardServiceProps = {
  name: string;
  info: string;
  className?: string;
  status: StatusesService;
  iconImage: ServiceImage;
};

const CardService = ({name, info, status, className, iconImage}: CardServiceProps) => {
  const techName = info || '--';
  const StatusIcon = () => {
    if (status === StatusesService.completed) return <Icon className="icon-large completed_icon" name="Check-Round-Fill" />;

    if (status === StatusesService.flagged) return <Icon className="icon-large flagged_icon" name="flag-filled" />;

    return <div className={cn('empty_icon', styles.icon_empty)} />;
  };

  return (
    <div className={cn(styles.container_services, className)}>
      <div className={cn(styles.container_edge, 'paddingRight-tiny1')}>
        <ImageByTest testid="service-image-icon" className={cn(styles.image_large, 'marginRight-small')} alt={name} src={iconImage?.thumb} />
        <div className={styles.container_center}>
          <div className={styles.name}>{name}</div>
          <div className={cn(styles.info, styles.icon_small)}>
            <Icon name="User" />
            <div className={cn(styles.info, 'paddingLeft-tiny')}>{techName}</div>
          </div>
        </div>
      </div>
      <div className={cn(styles.container_edge, styles.icon_medium)}>
        <StatusIcon />
      </div>
    </div>
  );
};

export default CardService;
