/* Libraries */
import {useRouter} from 'next/router';
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

/* Ducks */
import {userDuck} from '@features/User/User.ducks';

/* Utils */
import {isBrowser} from '@utils/env';
import {authToken} from '@utils/auth';

/* Constants */
import {homePath} from '@constants/constants.paths';

/* Types */
import {Children} from '@features/Application/application.types';
import {AnyAction} from '@reduxjs/toolkit';

type LayoutAuthProps = {
  children: Children;
};

const LayoutAuth = ({children}: LayoutAuthProps) => {
  /* Hooks */
  const router = useRouter();
  const dispatch = useDispatch();
  const technicianHasReduxId = useSelector(userDuck.selectors.getUserStateByKey('id'));

  useEffect(() => {
    if (isBrowser()) {
      if (!authToken.isValidJWT()) {
        router.push(homePath());
      } else if (!technicianHasReduxId) {
        // We have a token but apparently no id. Lets re-get the tech
        dispatch(userDuck.actions.getUserData() as unknown as AnyAction);
      }
    }
  }, [dispatch, router, technicianHasReduxId]);

  return <>{children}</>;
};

export const getAuthLayout = () => (page: $FixMe) => <LayoutAuth>{page}</LayoutAuth>;

export default LayoutAuth;
