import assign from 'lodash/assign';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import trim from 'lodash/trim';
import {createSlice, createEntityAdapter, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import router from 'next/router';
import get from 'lodash/get';
import * as TS from '@features/Skus/skus.types';
import * as ServiceTypes from '@features/Services/services.types';
import {ErrorResponse, IHash} from '@features/Application/application.types';
import {applicationDuck} from '@features/Application/Application.ducks';
import {SERVICE_QUESTION_TYPES} from '@features/Services/Services.constants';
import {RootState} from '@store/store';
import {extractSiteDataAnswers, extractSiteDataQuestions, createBasePreQuestionAnswerObject, formatDeviceAnswersForRedux} from './skus.utils';
import skusAPIs from './skus.api';

/*
 ************ ACTIONS
 */

export const asyncActions = {
  /**
    getPartnerSkus() fetches a list of skus with shallow information; only id, name, and icon
   */
  getPartnerSkus: createAsyncThunk<TS.GetPartnerSkusResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('sku/getPartnerSkus', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {partnerId, search = ''}: {partnerId: number; search: string} = params;

    const skusResponse = await skusAPIs.getPartnerSkus(null, {partner_id: partnerId, search});

    dispatch(applicationDuck.actions.setLoading(false));

    if (skusResponse.err) {
      return rejectWithValue(skusResponse as ErrorResponse);
    }

    return {...skusResponse, ...{partnerId}};
  }),
  /**
    getSkuById() fetches preQuestions for a sku
   */
  getSkuById: createAsyncThunk<TS.QuestionsAPI, any, {rejectValue: ErrorResponse; getState: RootState}>(
    'sku/getQuestionsBySkuId',
    async (params, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));

      const {pid, skuid} = router.query;
      const sku_id = skuid || params.skuId;

      const skuResponse = await skusAPIs.getSkuById({projectId: pid as string, sku_id});

      dispatch(applicationDuck.actions.setLoading(false));

      if (skuResponse.err) {
        return rejectWithValue(skuResponse as ErrorResponse);
      }

      const siteDataQuestions = extractSiteDataQuestions(skuResponse.data.sku);
      Object.assign(skuResponse.data.sku, {
        site_data_questions: siteDataQuestions,
      });
      return skuResponse;
    },
    {
      condition: ({skuId}, {getState}) => {
        const {skus} = getState() as RootState;
        const alreadyHaveQuestions = skus.questions?.entities?.[skuId!];

        if (alreadyHaveQuestions) {
          // Already fetched this question
          return false;
        }

        return true;
      },
    }
  ),
  /**
    No API call here, only validating the client's answers and updating Redux with any errors. See verifyQAForm.fulfilled for complete flow
   */
  verifyQAForm: createAsyncThunk<TS.QAErrors, void, {state: RootState}>('sku/verifyQAForm', async (_, {getState}) => {
    const {selectedSku}: {selectedSku: TS.SelectedSku} = getState().skus;
    const {questions} = getState().skus.questions.entities[selectedSku.skuId!];
    const requiredQuestions = questions.filter((q: TS.QuestionsAPIByQuestion) => q.required);
    const errors: TS.QAErrors = {};

    requiredQuestions.forEach((q: TS.QuestionsAPIByQuestion) => {
      const answer: TS.Answer = selectedSku.preQuestionAnswers[q.id as number] || null;
      let error = null;

      switch (q.input_type) {
        case TS.QuestionTypes.Input:
        case TS.QuestionTypes.Textarea:
          if (trim(answer.text) === '') {
            error = 'This field is required';
          }
          break;
        case TS.QuestionTypes.Dropdown:
          if (answer === null) {
            error = 'This field is required';
          }
          break;
        case TS.QuestionTypes.Checkbox:
          if (Array.isArray(answer)) {
            if (!answer.length) {
              error = 'Please select at least one option';
            }
          }
          break;
        case TS.QuestionTypes.Device: {
          const required = ['make', 'model'];
          const getErrors = required.reduce((seed, key) => {
            const keyRef = trim(get(answer, key) ?? '');
            if (!keyRef) seed[key] = 'This field is required';

            return seed;
          }, {} as IHash<string>);

          error = Object.values(getErrors).length ? getErrors : null;
          break;
        }
        default:
          throw new Error(`Unknown input type: ${q.input_type}`);
      }
      if (error) {
        errors[q.id] = error;
      }
    });
    return errors;
  }),
};

/*
*******************************************************
  ENTITY ADAPTORS
*******************************************************
*/

const skusAvailableByPartnerIdAdapter = createEntityAdapter<TS.SkusSortedByPartnerId>();
const skusAvailableByPartnerIdInitialState = skusAvailableByPartnerIdAdapter.getInitialState({});

const questionsAdapter = createEntityAdapter<TS.EntityQuestions>();
const questionsInitialState = questionsAdapter.getInitialState({});

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/

const defaultSelectedSku = {
  preQuestionAnswers: {},
  siteDataQuestions: {},
  autoApplyCoupon: null,
  partner: null,
  name: '',
  skuId: 0,
  lens: false,
  quantity: 1,
  skuName: '',
};

export const SKU_INITIAL_STATE = {
  questions: questionsInitialState, // Cache getSkuById() data from BE.
  skusAvailableByPartnerId: skusAvailableByPartnerIdInitialState, // Cache getPartnerSkus() data from BE with list of skus available to add. Sorted by partner id.
  selectedSku: defaultSelectedSku, // This is the sku data that we will read/write and and then submit to BE
  errors: {}, // verifyQAForm() validation errors
};

export const initialState = SKU_INITIAL_STATE;
/*
*******************************************************
  SLICE
*******************************************************
*/
const {actions: skuActions, reducer} = createSlice({
  name: 'skuState',
  initialState,
  reducers: {
    setSelectedSku: (state, action) => {
      const sku = action.payload.selectedSku;
      const {isEditMode, currentService = {} as ServiceTypes.ServiceDetails}: {isEditMode: boolean; currentService: ServiceTypes.ServiceDetails} = action.payload; // These are for editing
      if (isEditMode && state.questions) {
        /* Edit mode matches sku w/ previous answers from service */
        state.selectedSku = assign({}, state.selectedSku, pick({...sku, skuId: sku.id, quantity: 1}, keys(state.selectedSku)));
        const {questions} = state.questions?.entities[sku.id] || {questions: [] as TS.QuestionsAPIByQuestion[]};

        const formatPreviouslyAnsweredQuestions = () => {
          const questionsBase: any = createBasePreQuestionAnswerObject(questions);
          currentService?.pre_questions.forEach((pq: any) => {
            switch (pq.input_type) {
              case TS.QuestionTypes.Input:
              case TS.QuestionTypes.Textarea:
                questionsBase[pq.id] = {text: pq.answer || ''};
                break;
              case TS.QuestionTypes.Checkbox:
                questionsBase[pq.id] = pq.answers
                  ? pq.answers.map((a: any) => {
                      const quantity = a.quantity ? {quantity: a.quantity} : {};
                      return {id: a.pre_answer_id, ...quantity};
                    })
                  : [];
                break;
              case TS.QuestionTypes.Dropdown: {
                const answers = pq.answers || [];
                questionsBase[pq.id] = answers.length ? {id: answers[0].pre_answer_id} : null;
                break;
              }
              case TS.QuestionTypes.Device: {
                questionsBase[pq.id] = formatDeviceAnswersForRedux(pq);
                break;
              }
              default:
                break;
            }
          });
          return questionsBase;
        };
        state.selectedSku.preQuestionAnswers = formatPreviouslyAnsweredQuestions();
        state.selectedSku.siteDataQuestions = extractSiteDataAnswers(action.payload.currentService);
      } else {
        state.selectedSku = assign({}, state.selectedSku, pick({...sku, skuId: sku.id, quantity: 1}, keys(state.selectedSku)));
        // @ts-expect-error
        const {questions} = state.questions.entities[sku.id];
        state.selectedSku.preQuestionAnswers = createBasePreQuestionAnswerObject(questions);
      }
    },
    answerChange: (state, {payload}) => {
      const {question, value} = payload;
      const questions = question.type === SERVICE_QUESTION_TYPES.ON_SITE ? state.selectedSku.siteDataQuestions : state.selectedSku.preQuestionAnswers;

      if (!questions[question.id]) questions[question.id] = {};
      switch (question.input_type) {
        case TS.QuestionTypes.Input:
        case TS.QuestionTypes.Textarea:
          questions[question.id].text = value;
          break;
        case TS.QuestionTypes.Dropdown:
          questions[question.id] = value ? {id: value} : null;
          break;
        case TS.QuestionTypes.Checkbox:
          questions[question.id] = value.map((v: number) => ({id: v}));
          break;
        default: {
          // bugsnag
          throw new Error(`Unknown input_type: ${question.input_type}`);
        }
      }
    },
    answerQuantityChange: (state, {payload}) => {
      const {question, id, quantity} = payload;
      const answerIndex = state.selectedSku.preQuestionAnswers[question.id].findIndex((a: TS.QuestionsAPIByQuestion) => a.id === id);
      state.selectedSku.preQuestionAnswers[question.id][answerIndex].quantity = quantity;
    },
    deviceAnswerChange: (state, {payload}) => {
      const {questionType, question, makeValue, modelValue} = payload;
      const answerMatch = question.pre_answers.answers.find((a: {id: number}) => a.id === makeValue);
      /*
        BE needs the id of the productQuestion, not the answer id.
        But when editing answer, FE needs answer id.
      */
      let makeVal = get(answerMatch, 'product_question.id', null);
      let modelVal = modelValue;
      let answerId = makeValue;

      /*
        If answerMatch is not present, it means its either "other" or "idk" option
        The "other" option opens an inputfield which is addressed in "DEVICE_INPUT_ANSWER_CHANGE"
      */
      if (makeValue === TS.CustomDropdownValues.negOne) {
        answerId = null;
        makeVal = TS.CustomDropdownValues.negOne;
        modelVal = TS.CustomDropdownValues.negOne;
      }
      const data = {answerId, answerValue: makeValue, make: makeVal, model: modelVal};
      const values = makeValue || modelValue ? data : null;
      switch (questionType) {
        case 'makeDropdown':
          state.selectedSku.preQuestionAnswers[question.id.toString()] = values;
          break;
        case 'modelDropdown':
          state.selectedSku.preQuestionAnswers[question.id.toString()].model = modelVal;
          break;
        default:
        // nothing
      }
    },
    deviceInputAnswerChange: (state, {payload}) => {
      const {questionType, question, makeInputValue, modelInputValue} = payload;
      const data = {answerId: null, answerValue: makeInputValue, make: makeInputValue, model: modelInputValue};
      const values = makeInputValue || modelInputValue ? data : null;

      switch (questionType) {
        case 'makeInput':
          state.selectedSku.preQuestionAnswers[question.id.toString()] = values;
          break;
        case 'modelInput':
          state.selectedSku.preQuestionAnswers[question.id.toString()].model = modelInputValue;
          break;
        default:
        // nothing
      }
    },
    deleteErrors: state => {
      state.errors = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getPartnerSkus.fulfilled, (state, action) => {
        const {
          data: {skus},
        } = action.payload;

        const skusByPartnerId = {id: action.payload.partnerId, skus};

        skusAvailableByPartnerIdAdapter.upsertOne(state.skusAvailableByPartnerId, skusByPartnerId);
      })
      .addCase(asyncActions.getSkuById.fulfilled, (state, action) => {
        const {data} = action.payload;
        const {meta} = action;
        questionsAdapter.upsertOne(state.questions, {id: meta.arg.skuId, skuName: data.sku.name, questions: data.sku.pre_questions, siteDataQuestions: data.sku.site_data_questions});
      })
      .addCase(asyncActions.verifyQAForm.fulfilled, (state, action) => {
        state.errors = action.payload;
      });
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getSkusState = (state: RootState) => state?.skus ?? initialState;
/*

/*
 ************ EXPORTS
 */
const getQuestionStateByKey =
  (key: keyof TS.State) =>
  (addskuState: TS.State): Pick<TS.State, any> =>
    addskuState[key];

export const selectors = {
  getSkusAvailablePartnerIds: createSelector(getSkusState, (skuState): number[] => skuState.skusAvailableByPartnerId.ids), // Returns list of partner ids for who we have skus
  getSkusAvailableEntities: createSelector(getSkusState, (skuState): TS.SkusSortedByPartnerId => skuState.skusAvailableByPartnerId.entities),
  getSelectedSku: createSelector(getSkusState, (skuState): TS.SelectedSku => skuState.selectedSku),
  getKeyInQuestionState: (key: keyof TS.State) => createSelector(getSkusState, getQuestionStateByKey(key)),
  getQuestionsEntities: createSelector(getSkusState, skuState => skuState.questions.entities),
};

export const skusDuck = {
  actions: {...skuActions, ...asyncActions},
  reducer,
  selectors,
  initialState,
};
