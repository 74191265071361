import {Middleware} from 'redux';
import Bugsnag from '@bugsnag/js';

const bugsnagMiddleware: Middleware = () => next => action => {
  // report every action to bugsnag as a breadcrumb
  Bugsnag.leaveBreadcrumb(action?.type, action?.meta);
  return next(action);
};

export default bugsnagMiddleware;
