import {Tech} from '@features/Projects/projects.types';
import {ServiceImage, StatusesService} from '@features/Services/services.types';

export enum StatusesJob {
  cancelled = 'cancelled',
  unserviceable = 'unserviceable',
  completed = 'completed',
  not_started = 'not_started',
  pending_approval = 'pending_approval',
  in_progress = 'in_progress',
  flagged = 'flagged',
}

export enum BillingStatus {
  paid = 'paid',
  not_paid = 'not_paid',
}

export type UnitSearchFilter = {
  statuses: StatusesJob[];
  payment_statuses: BillingStatus[];
};

export type Unit = {
  id: number;
  unit_name: string;
  unit_floor: string | null;
  status: StatusesJob;
  service_count: number;
  flagged: boolean;
  cancellation_reason: {
    id: number;
    text: string;
    time: string;
  };
  cancellation_note: string;
  project_group: {
    id: number;
    name: string;
    project_services: UnitService[];
  };
  approved_by: {
    email: string;
    id: number;
    name: string;
  };
  phone: string;
  services: UnitService[];
  approved_at: string;
  cancelled_at: string;
  unserviceable_at: string;
  unserviceable_note: string;
  unserviceable_reason: {id: number; text: string};
  notes?: JobLogNote[];
  billing_status: BillingStatus | null;
  paid_at: string;
};

export type UnitWithServices = Unit & {
  product_services?: UnitService[];
};

export type UnitWithServicesResponse = {
  data: {
    unit: UnitWithServices;
  };
  error?: any;
};

export type UnitService = {
  id: number;
  status: StatusesService;
  tech: Tech;
  sku: {
    id: number;
    name: string;
    icon: ServiceImage;
  };
};

export type UnitResponsePayload = {
  data: UnitAndPagination & {
    status: 'success';
  };
};

export type UnitAndPagination = {
  units: Unit[];
  pagination: Pagination;
};

export type UnitIterate = {
  jobs: Unit[];
  pagination?: Pagination;
};

export type JobsState = {
  job: Unit | null;
  cancelReasons: CancelReasons;
  units: {
    [key in SearchTypes]: {
      jobs: Unit[];
      pagination: Pagination;
    };
  };
  count: number;
  searchType: SearchTypes;
  searchFilter: UnitSearchFilter;
  flagged: boolean;
  searchTerm: string;
  page: number;
  per_page: number;
};

/* Search Params */
export type SearchParams = Partial<{
  statuses: string[];
  project_group_ids: number[];
  only_flagged: boolean;
  search: string;
  page: number;
  per_page: number;
}>;

/* Pagination off of Search Response */
export type Pagination = Partial<{
  current_page: number;
  has_more: boolean;
  total_pages: number;
  total_units: number;
  units_on_current_page: number;
  units_per_page: number;
  units_viewed: number;
}>;

/* Search Count Payload Response */
export type CountPayloadResponse = {
  data: {
    status: string;
    count: number;
  };
};

/* Enum Search Types */
export enum SearchTypes {
  keyword = 'keyword',
  status = 'status',
}

/* Search Props in Components */
export type SearchComponentProps = {
  loadedUnits: UnitIterate;
  handleListItemClick: (arg: number) => any;
  swipeLeftActions: (arg: Unit) => () => React.ReactNode;
  swipeRightActions: (arg: Unit) => () => React.ReactNode;
  handleSwipeProgress: (arg: number) => (arg: number) => any;
  searchType: SearchTypes;
};

/* Create a Job */
export type CrudJobParams = {
  project_group_id?: number | null;
  unit_name: string;
};

export type DeleteJobParams = {
  unit_id?: string | string[] | number | undefined;
  unit_name?: string;
};

/* Get Specific Job */
export type JobDetailsParams =
  | {
      id?: string;
    }
  | undefined;

/* Cancel Reasons */
export type CancelReasonsResponse = {
  data: {
    reasons: CancelReasons;
  };
};

export enum CancellationTypes {
  cancellation = 'cancellation',
  unserviceable = 'unserviceable',
  flagged = 'service_flag',
}

export type CancelReasons =
  | {
      [key in CancellationTypes]: CancelReasonType[];
    }
  | null;

/* Cancel reasons */
export type CancelReasonType = {
  id: number;
  text: string;
  type: string;
};

/* Job Log */
export type JobLogNote = {
  content: string;
  content_sanitized: string;
  created_at: string;
  created_at_formatted: string;
  id: number;
  title: string;
  tech: Tech;
};

export type GetJobLogNotesResponse = {
  data: {
    notes: JobLogNote[];
  };
};

export type CreateJobLogNoteResponse = {
  data: {
    note: JobLogNote;
    status: string;
  };
};

export enum DisplayErrorEnum {
  inline = 'inline',
}

export type DisplayErrorType = {
  displayErrorType: DisplayErrorEnum;
};
