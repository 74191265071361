import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import cn from 'classnames';

/* Store */
import {useAppDispatch, useAppSelector} from '@store/store';

/* Ducks */
import {jobsDuck} from '@features/Jobs/Jobs.ducks';
import {projectsDuck} from '@features/Projects//Projects.ducks';

/* Components */
import HeaderList from '@features/Projects/Parts/Header/Header.list';
import EmptyResults, {EmptyResultsProps} from '@components/UI/Empty';
import GridMdu from '@components/UI/GridMdu';
import ProjectView from '@features/Projects/Parts/ProjectView/ProjectView';

/* Types */
import {StatusesProjectFilter, ProjectsState, PausedStatusTypes} from '@features/Projects/projects.types';
import {BASE_PROJECT_SEARCH_FILTERS} from '@features/Projects/projects.constants';

/* Styles */
import styles from './projects.module.scss';
import {SearchTypes} from '../Jobs/jobs.types';

type GetEmptyResultsConfigArgs = {searchType: SearchTypes; searchFilters: ProjectsState['searchFilters']; isSearchComplete?: boolean};

const getEmptyResultsConfig = ({searchType, searchFilters, isSearchComplete}: GetEmptyResultsConfigArgs) => {
  const config = {} as EmptyResultsProps;

  if (searchType === SearchTypes.status) {
    config.icon = 'Error';
    const {statuses} = searchFilters;
    if (statuses.length === 1 && statuses[0] === StatusesProjectFilter.not_completed) config.title = 'You have no projects in progress';
    if (statuses.length === 0 && searchFilters.paused === PausedStatusTypes.ONLY_PAUSED) config.title = 'You have no paused projects';
    if (statuses.length === 1 && statuses[0] === StatusesProjectFilter.completed) config.title = 'You have no completed projects';
  }
  if (searchType === SearchTypes.keyword) {
    config.title = isSearchComplete ? 'No Results' : 'Search for a project by name';
    config.icon = isSearchComplete ? 'Error' : 'Search';
  }
  return config;
};

const ProjectHome = () => {
  /* Hooks */
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const projectsByType = useAppSelector(projectsDuck.selectors.getAllProjectsByType);
  const searchType = useAppSelector(projectsDuck.selectors.getSearchType);
  const filteredProjects = projectsByType[searchType].projects;
  const searchFilters = useAppSelector(projectsDuck.selectors.getSearchFilters);
  const searchTerm = useAppSelector(projectsDuck.selectors.getSearchTerm);
  const searchFiltersStatuses = searchFilters.statuses;

  /* Data */
  const showEmptyResults = !filteredProjects.length && loaded;
  const emptyResultsConfig = getEmptyResultsConfig({searchType, searchFilters, isSearchComplete: Boolean(loaded && searchTerm)});
  const projectViews = {
    [SearchTypes.keyword]: ProjectView.ByKeyword,
    [SearchTypes.status]: ProjectView.ByStatus,
  };

  const FilteredProjectView = projectViews[searchType] || ProjectView.ByKeyword;

  useEffect(() => {
    // Handle initial page load
    if (!router.isReady) return;

    (async () => {
      setLoaded(false);
      // Reset to a default if searchFilters was set to an invalid configuration
      if (!searchFiltersStatuses.length && searchFilters.paused !== PausedStatusTypes.ONLY_PAUSED) {
        await dispatch(projectsDuck.actions.setSearchFilters(BASE_PROJECT_SEARCH_FILTERS.IN_PROGRESS));
      }
      await dispatch(projectsDuck.actions.getProjectsByType());
      setLoaded(true);
    })();
  }, [router.isReady, dispatch, searchFiltersStatuses.length, searchFilters.paused]);

  const fixedHeaderHeightAndSpacing = searchType === SearchTypes.status ? '264px' : '214px';

  /* Clear any residual search activity from previous */
  useEffect(() => {
    dispatch(jobsDuck.actions.clearFullSearch());
  }, []);

  return (
    <div className={styles.page}>
      <HeaderList setLoaded={setLoaded} />
      <GridMdu.Fluid>
        <div className={cn(styles.container, 'paddingBottom-huge')} style={{paddingTop: fixedHeaderHeightAndSpacing}}>
          {showEmptyResults ? <EmptyResults {...emptyResultsConfig} /> : <FilteredProjectView projects={filteredProjects} />}
        </div>
      </GridMdu.Fluid>
    </div>
  );
};

export default ProjectHome;
