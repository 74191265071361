import React from 'react';
import cn from 'classnames';
import styles from './card.module.scss';

type CardJobProps = {
  name: string;
  info: string;
  RightComponent: React.ReactNode;
  className?: string;
};

const CardJob = ({name, info, RightComponent, className}: CardJobProps) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.container_center}>
      <div className={cn('p0 text-weight-medium', styles.name)}>{name}</div>
      <div className={cn('paddingTop-tiny', styles.info)}>{info}</div>
    </div>
    <div className={styles.container_edge}>{RightComponent}</div>
  </div>
);

export default CardJob;
