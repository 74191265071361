// @ts-nocheck
import React from 'react';
import styles from '@features/Projects/projects.module.scss';

function ProgressMain({
  progress = 0,
  strokeWidth = 4,
  ballStrokeWidth = 16,
  reduction = 0.25,
  transitionDuration = 0.5,
  transitionTimingFunction = 'ease',
  background = '#dde2e9',
  hideBall = false,
  hideValue = false,
  gradient = [
    {
      stop: 0.0,
      color: '#00bc9b',
    },
    {
      stop: 1,
      color: '#5eaefd',
    },
  ],
  subtitle = '',
  style = {},
  className = '',
}) {
  // eslint-disable-next-line no-param-reassign
  progress = Math.round(progress * 100) / 100;
  const width = 200;
  const center = width / 2;
  const height = 200 || center + center * Math.cos(reduction * Math.PI);
  const [unique] = React.useState(() => Math.random().toString());
  const rotate = 90 + 180 * reduction;
  const r = center - strokeWidth / 2 - ballStrokeWidth / 2;
  const circumference = Math.PI * r * 2;
  const offset = (circumference * (100 - progress * (1 - reduction))) / 100;
  return /* #__PURE__ */ React.createElement(
    'div',
    {
      className: `${className} ${styles.progress}`,
      style,
    },
    /* #__PURE__ */ React.createElement(
      'svg',
      {
        viewBox: `0 0 ${width} ${height}`,
        className: styles.svg,
      },
      /* #__PURE__ */ React.createElement(
        'defs',
        null,
        /* #__PURE__ */ React.createElement(
          'linearGradient',
          {
            id: `gradient${unique}`,
            x1: '0%',
            y1: '0%',
            x2: '0%',
            y2: '100%',
          },
          gradient.map(({stop, color}) =>
            /* #__PURE__ */ React.createElement('stop', {
              key: stop,
              offset: `${stop * 100}%`,
              stopColor: color,
            })
          )
        )
      ),
      !hideValue &&
        /* #__PURE__ */ React.createElement(
          'text',
          {
            x: center,
            y: center + 20,
            textAnchor: 'middle',
            fontSize: '50',
            fill: '#3c3c3c',
          },
          `${progress} %`
        ),
      /* #__PURE__ */ React.createElement(
        'text',
        {
          x: center,
          y: center + (3 * 3) / 4,
          textAnchor: 'middle',
          fill: '#9c9c9c',
        },
        subtitle
      ),
      /* #__PURE__ */ React.createElement('circle', {
        transform: `rotate(${rotate} ${center} ${center})`,
        id: 'path',
        cx: center,
        cy: center,
        r,
        strokeWidth,
        strokeDasharray: circumference,
        strokeDashoffset: circumference * reduction,
        fill: 'none',
        stroke: background,
        strokeLinecap: 'round',
      }),
      /* #__PURE__ */ React.createElement('circle', {
        style: {
          transition: `stroke-dashoffset ${transitionDuration}s ${transitionTimingFunction}`,
        },
        transform: `rotate(${rotate} ${center} ${center})`,
        id: 'path',
        cx: center,
        cy: center,
        r,
        strokeWidth,
        strokeDasharray: `${circumference}`,
        strokeDashoffset: offset,
        fill: 'none',
        stroke: `url(#gradient${unique})`,
        strokeLinecap: 'round',
      }),
      !hideBall &&
        /* #__PURE__ */ React.createElement('circle', {
          style: {
            transition: `stroke-dashoffset ${transitionDuration}s ${transitionTimingFunction}`,
          },
          transform: `rotate(${rotate} ${center} ${center})`,
          id: 'path',
          cx: center,
          cy: center,
          r,
          strokeWidth: ballStrokeWidth,
          strokeDasharray: `1 ${circumference}`,
          strokeDashoffset: offset,
          fill: 'none',
          stroke: `url(#gradient${unique})`,
          strokeLinecap: 'round',
        })
    )
  );
}

export const Progress = /* #__PURE__ */ React.memo(ProgressMain);
Progress.displayName = 'Progress';
export default Progress;
