import {CUSTOM_DROPDOWN_OPTIONS} from '@features/Skus/skus.constants';
import {CreateServicePreQuestionAnswersRequest, CustomDropdownValues, ModelAnswer, ProductQuestion, SelectedSku, Sku} from '@features/Skus/skus.types';
import {IHash} from '@features/Application/application.types';
import * as SkuTypes from '@features/Skus/skus.types';
import intersection from 'lodash/intersection';
import {SERVICE_QUESTION_TYPES} from '@features/Services/Services.constants';
import {ServiceDetails, ServiceDetailsQuestions} from '@features/Services/services.types';

/**
 * errorIds should be an array of questions ids that have errors (Redux)
 * questionIds should be an array of question ids to test against.
 * Because the validation is performed in 2 spots, AddEditSku, and Services.detailpage, we may need to pass different data at different times
 */
export const isQaFormErrorFree = ({errorIds, questionIds}: {errorIds: string[]; questionIds: string[]}) => {
  const matches = intersection(errorIds, questionIds);
  return !matches.length;
};
/**
 * Setup an object to track responses to answers to questions.
 * @param qs
 */
export const createBasePreQuestionAnswerObject = (qs: SkuTypes.QuestionsAPIByQuestion[]) => {
  const newQuestions: IHash<IHash<string | number> | any[] | null> = {};
  qs.forEach(q => {
    switch (q.input_type) {
      case SkuTypes.QuestionTypes.Input:
      case SkuTypes.QuestionTypes.Textarea:
        newQuestions[q.id.toString()] = {text: ''};
        break;
      case SkuTypes.QuestionTypes.Dropdown: {
        const defaultAnswer = q.answers.find(a => a.default);
        newQuestions[q.id.toString()] = defaultAnswer ? {id: defaultAnswer.id} : null;
        break;
      }
      case SkuTypes.QuestionTypes.Checkbox:
        newQuestions[q.id.toString()] = [];
        break;
      case SkuTypes.QuestionTypes.Device: {
        const defaultAnswer = q.answers.find(a => a.default);
        newQuestions[q.id.toString()] = defaultAnswer ? {id: defaultAnswer.id} : null;
        break;
      }
      default: {
        throw new Error(`Unknown input_type: ${q.input_type}`);
      }
    }
  });
  return newQuestions;
};

/**
 * Take the answers we have in Redux under selectedSku.preQuestionAnswers and format an object that the
 * BE will use to add/edit a service.
 */
export const formatPreQuestionAnswersRequest = ({rawSkuData, selectedSku}: {rawSkuData: Sku; selectedSku: SelectedSku}) => {
  const entries = Object.entries(selectedSku.preQuestionAnswers);
  const answersSortedBySkuId = entries.map(e => ({id: e[0], answers: e[1]}));
  const formattedRawData = rawSkuData.questions.reduce((acc, pq) => {
    const match = answersSortedBySkuId.find(answer => Number(answer.id) === Number(pq.id));
    let answerBase: CreateServicePreQuestionAnswersRequest = {...pq, preQuestionId: pq.id};
    switch (pq.input_type) {
      case SkuTypes.QuestionTypes.Input:
      case SkuTypes.QuestionTypes.Textarea:
        answerBase = {...answerBase, answer: match?.answers.text};
        break;
      case SkuTypes.QuestionTypes.Checkbox: {
        answerBase = {
          ...answerBase,
          answers: match?.answers.map((a: any) => {
            const quantity = a.quantity ? {quantity: a.quantity} : {};
            return {preAnswerId: a.id, ...quantity};
          }),
        };
        break;
      }
      case SkuTypes.QuestionTypes.Dropdown:
        answerBase = {...answerBase, answers: [{preAnswerId: match?.answers?.id}]};
        break;
      case SkuTypes.QuestionTypes.Device: {
        /*
          Device questions are not answered/edited from Add/Edit page, only ServiceDetails page. So there are two Question/Answer
          forms to handle, not just one(e.g.Admin/Clients) . To make this work we have to treat device questions specially to
          get the expected results.

          When adding a new service there won't be any answers to the device questions. The user will answer those on ServiceDetailsPage AFTER adding the service.
          If the answer object is provided here then the BE expect valid answers, so don't send answer object for adding new services, only editing.
        */
        const isEditingService = match?.answers;
        if (isEditingService) {
          answerBase = {
            ...answerBase,
            answer: {
              product_make_id: match?.answers?.answerId || match?.answers?.answerValue || '',
              product_id: match?.answers?.model || '',
            },
          };
        }
        break;
      }
      default:
        break;
    }
    acc.push(answerBase);
    return acc;
  }, [] as any[]);

  return formattedRawData;
};

export const formatSiteDataQuestionsRequest = ({selectedSku, service}: {selectedSku: SelectedSku; service: ServiceDetails}) => {
  const {siteDataQuestions} = selectedSku;
  const site_data_questions = Object.keys(siteDataQuestions).reduce((ret, key) => {
    if (key === '-1') return ret;
    const question = siteDataQuestions[key];
    return Object.assign(ret, {
      [key]: {
        answer: question.text,
      },
    });
  }, {});

  return {
    site_data: {
      [service.id]: site_data_questions,
    },
    additional_information: {
      [service.id]: siteDataQuestions['-1'].text,
    },
  };
};

/* DEVICE CATALOGING utils from New Admin */

export const formatDeviceAnswersForRedux = (preQuestion: any) => {
  if (!preQuestion.product) return null;
  if (preQuestion.custom_product) {
    return {
      answerId: null,
      answerValue: preQuestion.make_text,
      make: preQuestion.make_text,
      model: preQuestion.product_text,
    };
  }
  return {
    answerId: preQuestion.answer.product_make_id || preQuestion.answer.product_make || null,
    answerValue: preQuestion.answer.product_make_id || preQuestion.answer.product_make || null,
    make: preQuestion.answer.product_make_id || preQuestion.answer.product_make || null,
    model: preQuestion.answer.product_id || preQuestion.answer.product || null,
  };
};

/**
 * Format device make for dropdown options list
 */
export const getDeviceMakeOptions = (question: SkuTypes.GenericQuestion) => question?.pre_answers?.answers.map(a => ({value: a.id, label: a.text}));

/**
 * Format device model for dropdown options list
 */
export const getDeviceModelOptions = ({products}: {products?: ProductQuestion}) => {
  if (products) {
    const modelOptions = products.answers.map(p => ({value: p.id as CustomDropdownValues | number, label: p.name}));
    // push custom options 'other' & 'idk'
    modelOptions.push(CUSTOM_DROPDOWN_OPTIONS.OTHER);
    modelOptions.push(CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW);
    return modelOptions;
  }
  return null;
};

export const getSelectedModel = ({questionAnswers, valueId}: {questionAnswers: ModelAnswer[]; valueId: number | string}) => {
  const selectedModel = questionAnswers.find(a => {
    if (!a.product_question) return null;
    return valueId === a.id && a.product_question.answers;
  });
  return selectedModel;
};

export const getModelQuestion = ({answers}: {answers: ModelAnswer[]}) => answers?.[0]?.product_question?.text;

export const getAnswerWarning = (q: SkuTypes.GenericQuestion, val: number | string) => {
  if (!val || !q) return null;
  let hint = null;
  q.pre_answers.answers.forEach(answer => {
    if (answer.id === val) {
      hint = answer.warning;
    }
  });
  return hint;
};

export function extractSiteDataQuestions(data: ServiceDetailsQuestions) {
  return [
    ...(data.additional_information_question
      ? [
          {
            active: true,
            id: -1,
            index: -1,
            text_direct: data.additional_information_question,
            sku_id: null,
          },
        ]
      : []),
    ...(data.site_data_questions || []),
  ].map((q: any) => ({
    ...q,
    type: SERVICE_QUESTION_TYPES.ON_SITE,
    input_type: SkuTypes.QuestionTypes.Input,
    text_direct: q.text_direct || q.question,
    hint: null,
  }));
}

export function extractSiteDataAnswers(data?: ServiceDetails) {
  if (!data) return {};
  const siteDataAnswers = {
    '-1': {
      text: data.additional_information_answer,
    },
  };
  (data.site_data_answers || []).forEach(answer => {
    siteDataAnswers[answer.site_data_question_id] = {
      text: answer.answer,
    };
  });
  return siteDataAnswers;
}
