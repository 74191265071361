import {Icon} from 'ht-styleguide';
import cn from 'classnames';
import {formatUnitStatus} from '@features/Jobs/jobs.utils';
import {BillingStatus, Unit} from '@features/Jobs/jobs.types';

import styles from '@features/Jobs/jobs.module.scss';

const JobStatusListDisplay = (unit: Unit) => {
  /* Styles */
  const text = cn('text_status', {
    [`${unit.status}_text`]: true,
  });

  return (
    <div className={styles.status_container}>
      <div className={text}>{formatUnitStatus(unit.status)}</div>
      <div className="inlineIcon">
        {unit.billing_status === BillingStatus.paid && <Icon className="completed_icon" name="money-solid" />}
        {unit.flagged && <Icon className="flagged_icon" name="flag-filled" />}
      </div>
      <style jsx>{`
        .text_status {
          font-size: 10px;
          text-align: right;
        }
        .inlineIcon {
          font-size: 14px;
        }
      `}</style>
    </div>
  );
};

export default JobStatusListDisplay;
