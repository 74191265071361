/*
  Our user Permissions. Derived from "current_user" attached to project
  Note: we are not using read/write/execute level, but rather a layer level.
  Each layer represents a 'type', and no type can "see/interact" to layers
  above it.

  For instance, a "lead_tech" cannot see/adjust items particular to admin.
  A minimum interaction will be "tech".
*/
export enum PermissionRoles {
  admin = 1,
  billing_lead = 2,
  lead_tech = 3,
  tech = 4,
}
