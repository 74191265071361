import identity from 'lodash/identity';

/**
 *
 * @param OBJ - base object we are trying to derive our values
 * @param keys - our key references we want to get our values from
 * @param actionOnResults - action on the result set
 */
function getValuesByKey<T extends object>(OBJ: T, keys: string[], actionOnResults: Function = identity) {
  const result: string[] = [];

  if (!OBJ) return OBJ;

  /**
   * Our internal recursive function.
   * This will go thru the response, recursively, and get the values
   * from the keys you specify. Obviously, not fool proof in terms of
   * asking for certain keys.
   *
   * @param this - passed context in foreach
   * @param k
   */
  // @ts-ignore
  function recursiveIterator<U extends Record<string, unknown>>(this: U, k: string) {
    const value = this?.[k] ?? k;

    if (this && keys.includes(k)) {
      return result.push(...([] as string[]).concat(value as string));
    }
    if (Array.isArray(value)) {
      return value.forEach(recursiveIterator);
    }
    if (value !== null && typeof value === 'object') {
      return Object.keys(value!).forEach(recursiveIterator, value);
    }

    return result;
  }

  Object.keys(OBJ).forEach(recursiveIterator, OBJ);
  return actionOnResults(result);
}

export default getValuesByKey;
