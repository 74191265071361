import React from 'react';
import cn from 'classnames';
import {Panel} from 'ht-styleguide';
import {Children} from 'src/features/Application/application.types';
import styles from './menu.module.scss';

const MenuPanel = ({children, className}: {children: Children; className?: string}) => (
  <Panel largeBorderRadius className={cn(styles.editMenu, className)}>
    {children}
  </Panel>
);

export default MenuPanel;
