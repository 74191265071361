import {get, post, put, destroy} from '@integrations/axios';

const servicesAPIs = {
  flagService: post('rails', '/api/techs/projects/::project_id/units/::unit_id/services/::service_id/flag'),
  resolveFlag: destroy('rails', '/api/techs/projects/::project_id/units/::unit_id/services/::service_id/flag'),
  removeService: destroy('rails', '/api/techs/projects/::project_id/units/::unit_id/services/::service_id'),
  getServiceDetails: get('rails', '/api/techs/projects/::project_id/units/::unit_id/services/::service_id'),
  updateService: put('rails', '/api/techs/projects/::project_id/units/::unit_id/services/::service_id'),
  deleteService: destroy('rails', '/api/techs/projects/::project_id/units/::unit_id/services/::service_id'),
  performService: post('rails', '/api/techs/projects/::project_id/units/::unit_id/services/::service_id/perform'),
  revertService: post('rails', '/api/techs/projects/::project_id/units/::unit_id/services/::service_id/revert'),
  createService: post('rails', '/api/techs/projects/::project_id/units/::unit_id/services'),
};

export default servicesAPIs;

// techs/projects/28/units/803501/services/
