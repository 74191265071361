import React from 'react';
import {ToastContainer} from 'react-toastify';
import NotificationCloseIcon from './NotificationCloseIcon';

const Notification = ({...props}) => (
  <ToastContainer
    data-testid="notification_error"
    limit={1}
    position="top-center"
    autoClose={5000}
    closeOnClick
    pauseOnFocusLoss
    draggable
    pauseOnHover
    {...props}
    closeButton={<NotificationCloseIcon />}
  />
);

export default Notification;
