/*
  Grabbed this from the tech-app since this will all eventually go there.
  This way, the apis align. Some things are different, but we can throw this away
  and it should still work (in the ducks files).
 */

import axios, {AxiosRequestConfig} from 'axios';

/* Utils */
import {logger} from '@utils/logger';
import {authToken} from '@utils/auth';

/* Types */
import {IHash} from '@features/Application/application.types';

/**
 * Create Custom Axios Instances
 */
const baseOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const axiosRails = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST_FULL_URL,
  ...baseOptions,
});

const axiosTechApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST_FULL_URL_TECHAPI,
  ...baseOptions,
});

[axiosRails, axiosTechApi].forEach(instance => {
  instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = await authToken.getAuthToken();

    if (config.headers === undefined) {
      config.headers = {};
    } else {
      config.headers['Auth-Token'] = token || '';
    }

    return config;
  });

  instance.interceptors.response.use(
    response => response,
    err => {
      err.data = null;
      err.networkError = false;

      if (err.response) {
        const {data, status, statusText} = err.response;
        err.data = data;
        err.status = status;
        err.statusText = statusText;

        return Promise.reject(err);
      }

      if (err.request) {
        const {status, statusText, readyState, responseUrl, timeout} = err.request;
        err.status = status;
        err.statusText = statusText;
        /*
          This could mean a few things: device has spotty network, BE is hanging on the request,
          errors from cross-domain requests
          https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
        */
        err.networkError = err.message === 'Network Error';

        logger('axios request error')(err, event => {
          event.addMetadata('extra', {
            readyState,
            responseUrl,
            status,
            statusText,
            timeout,
          });
        });
      } else {
        logger('axios unknown error')(err);
      }

      return Promise.reject(err);
    }
  );
});

type TargetType = 'rails' | 'techAPI' | 'techLocation';
const httpClients = {
  rails: axiosRails,
  techAPI: axiosTechApi,
};

/**
 * Helpers
 */
type PathParamsType = IHash<string> | null;
type QueryParamsType = IHash<any> | null;
type DataType = IHash<any> | null;

const DELIM = '::';

const resolvePath = (path: string, pathParams?: PathParamsType) => {
  const shouldHaveReplacements = path.replace(/(^https?:|)\/\//, '').includes(DELIM);
  if (shouldHaveReplacements && !pathParams) {
    throw new Error(`Please provide attributes for path: ${path}`);
  }

  let newPath = path;

  if (shouldHaveReplacements) {
    Object.keys(pathParams!).forEach(key => {
      if (newPath.includes(`${DELIM}${key}`)) {
        newPath = newPath.replace(`${DELIM}${key}`, pathParams![key]);
      } else {
        throw new Error(`Can't find ${DELIM}${key} in ${newPath}`);
      }
    });
    if (newPath.replace(/(^https?:|)\/\//, '').includes(DELIM)) {
      throw new Error(`Not all replacements were provided: ${newPath}`);
    }
  }

  return newPath;
};

/**
 * Requests
 */
type GenerateType = {
  path: string;
  pathParams?: PathParamsType;
  queryParams?: QueryParamsType;
  data?: DataType;
  requestConfig?: AxiosRequestConfig;
};

const generateRequestConfig = ({path, pathParams, queryParams, data, requestConfig}: GenerateType): AxiosRequestConfig => {
  const resolvedPath = resolvePath(path, pathParams);
  return {
    url: resolvedPath,
    data,
    params: queryParams,
    ...(requestConfig || {}),
  } as AxiosRequestConfig;
};

export const get = (target: TargetType, path: string) => async (pathParams?: PathParamsType, queryParams?: QueryParamsType, requestConfig?: AxiosRequestConfig) => {
  const axiosInstance = httpClients[target];
  const newRequestConfig = generateRequestConfig({
    path,
    pathParams,
    queryParams,
    requestConfig: {method: 'get', ...(requestConfig || {})},
  });
  try {
    const response = await axiosInstance(newRequestConfig);
    const {data: responseData, status, statusText} = response;
    return {data: responseData, status, statusText};
  } catch (e) {
    // @ts-ignore
    const {message, status, statusText, data: responseData, networkError} = e;
    return {err: message, data: responseData, status, statusText, networkError};
  }
};

export const post = (target: TargetType, path: string) => async (pathParams?: PathParamsType, data?: DataType, requestConfig?: AxiosRequestConfig) => {
  const axiosInstance = httpClients[target];
  const newRequestConfig = generateRequestConfig({
    path,
    pathParams,
    data,
    requestConfig: {method: 'post', ...(requestConfig || {})},
  });

  try {
    const response = await axiosInstance(newRequestConfig);
    const {data: responseData, status, statusText} = response;
    return {data: responseData, status, statusText};
  } catch (e) {
    // @ts-ignore
    const {message, status, statusText, data: responseData, networkError} = e;
    return {err: message, data: responseData, status, statusText, networkError};
  }
};

export const put = (target: TargetType, path: string) => async (pathParams?: PathParamsType, data?: DataType, requestConfig?: AxiosRequestConfig) => {
  const axiosInstance = httpClients[target];
  const newRequestConfig = generateRequestConfig({
    path,
    pathParams,
    data,
    requestConfig: {method: 'put', ...(requestConfig || {})},
  });
  try {
    const response = await axiosInstance(newRequestConfig);
    const {data: responseData, status, statusText} = response;
    return {data: responseData, status, statusText};
  } catch (e) {
    // @ts-ignore
    const {message, status, statusText, data: responseData, networkError} = e;
    return {err: message, data: responseData, status, statusText, networkError};
  }
};

export const destroy = (target: TargetType, path: string) => async (pathParams?: PathParamsType, data?: DataType, requestConfig?: AxiosRequestConfig) => {
  const axiosInstance = httpClients[target];
  const newRequestConfig = generateRequestConfig({
    path,
    pathParams,
    data,
    requestConfig: {method: 'delete', ...(requestConfig || {})},
  });
  try {
    const response = await axiosInstance(newRequestConfig);
    const {data: responseData, status, statusText} = response;
    return {data: responseData, status, statusText};
  } catch (e) {
    // @ts-ignore
    const {message, status, statusText, data: responseData, networkError} = e;
    return {err: message, data: responseData, status, statusText, networkError};
  }
};
