import {Button, BUTTON_THEMES} from 'ht-styleguide';
import React from 'react';

/* styles */
import styles from './floatingbutton.module.scss';

type FloatingActionButtonProps = {
  buttonAction: BaseAnyFunction;
  title: string;
  suppress: boolean;
  theme?: string;
};

const FloatingActionButton = ({buttonAction, title, suppress, theme = BUTTON_THEMES.PRIMARY}: FloatingActionButtonProps) => {
  if (suppress) return null;

  return (
    <div onClick={buttonAction} className={styles.floating_button_container}>
      <Button dataTestId={title.replace(/\s/g, '')} theme={theme}>
        {title}
      </Button>
    </div>
  );
};

export default FloatingActionButton;
