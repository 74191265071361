import React, {ChangeEvent, useEffect, useState} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import {SwipeableList, SwipeableListItem, TrailingActions, LeadingActions, Type as SwipeableListType} from 'react-swipeable-list';
import {Button, BUTTON_THEMES, CalloutBox, htToast, CalloutBoxThemes} from 'ht-styleguide';
/* Store */
import {useAppDispatch, useAppSelector} from '@store/store';
/* Components */
import GridMdu from '@components/UI/GridMdu';
import {CardService} from '@components/UI/Card';
import StatusApprovalButton from '@features/Jobs/Parts/Jobs.statusapprovalbutton';
/* Ducks */
import {jobsDuck} from '@features/Jobs/Jobs.ducks';
import {projectsDuck} from '@features/Projects';
import {userDuck} from '@features/User/User.ducks';
/* Components */
import JobDetailHeader from '@features/Jobs/Parts/JobDetailHeader';
import SwipeButton, {SwipeButtonTypes} from '@components/UI/SwipeButton';
import EmptyResults from '@components/UI/Empty';
/* Paths */
import {projectListPath, servicePath, createNewServicePath} from '@constants/constants.paths';
/* Utils */
import {updateRouteByModalRequest} from '@utils/route';
import {sortServices} from '@features/Jobs/jobs.utils';
/* Constants */
import {CallOutBoxStatus, shortDayMonthWithYearTime} from '@constants/constants.base';
/* Types */
import {BillingStatus, StatusesJob, UnitService} from '@features/Jobs/jobs.types';
import {ModalNames} from '@features/Projects/projects.types';
import {StatusesService} from '@features/Services/services.types';
import {IHash} from '@features/Application/application.types';

/* Styles */
import styles from './jobs.module.scss';

/* Dynamic Component Imports */
const UpdateJobModalDynamic = dynamic(() => import('@features/Jobs/Modals/Jobs.update'), {ssr: false});
export const ServiceRemoveModalDynamic = dynamic(() => import('@features/Services/Modals/Modal.serviceremove'), {ssr: false});
export const FlagServiceModalDynamic = dynamic(() => import('@features/Services/Modals/Modal.flagservice'), {ssr: false});
export const UnflagServiceModalDynamic = dynamic(() => import('@features/Services/Modals/Modal.unflagservice'), {ssr: false});
const JobLogModalDynamic = dynamic(() => import('@features/Jobs/Modals/Modal.joblog'), {ssr: false});
const NoCompleteModalDynamic = dynamic(() => import('@features/Jobs/Modals/Jobs.nocomplete'), {ssr: false});
const DeleteJobModalDynamic = dynamic(() => import('@features/Jobs/Modals/Jobs.delete'), {ssr: false});

const NotificationOfFailedCompleteOperation = ({pid, jid, sid}: IHash<string | number>) => (
  <>
    <div>
      Please fill out any missing fields before completing this service.
      <Link href={servicePath(pid, jid, sid)} className={cn('paddingLeft-tiny', styles.inlineAlertAnchor)}>
        See details
      </Link>
    </div>
  </>
);

const JobHome = () => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    push,
    query: {jid, pid, updatejob, removeservice, flagservice, nocomplete, unflagservice, joblog, deletejob, refferer},
  } = router;

  /* Local State */
  const [openSwipedItems, setOpenSwipedItems] = useState<number[]>([]);
  const [selectedService, setSelectedService] = useState<UnitService>();
  const [jobLogInputText, setInputText] = useState<string>('');

  /* Selectors */
  const unit = useAppSelector(jobsDuck.selectors.getCurrentJob);
  const currentProject = useAppSelector(projectsDuck.selectors.getCurrentProject(pid as string));
  const formattedCompletedDate = useAppSelector(userDuck.selectors.getFormattedDateByUserTimezone({format: shortDayMonthWithYearTime, date: unit?.approved_at}));
  const formattedCancelledDate = useAppSelector(userDuck.selectors.getFormattedDateByUserTimezone({format: shortDayMonthWithYearTime, date: unit?.cancelled_at}));
  const formattedUnserviceableDate = useAppSelector(userDuck.selectors.getFormattedDateByUserTimezone({format: shortDayMonthWithYearTime, date: unit?.unserviceable_at}));
  const formattedPaidAtDate = useAppSelector(userDuck.selectors.getFormattedDateByUserTimezone({format: shortDayMonthWithYearTime, date: unit?.paid_at}));

  /* Methods */
  const resetInputText = () => setInputText('');
  const setInputValue = (fn: React.SetStateAction<any>) => (evt: ChangeEvent<HTMLInputElement>) => fn(evt.target.value);

  const getHeaderBackClickPath = (projectid: string) => {
    const config: {[key: string]: any} = {pathname: projectListPath(projectid)};
    if (refferer) {
      config.query = {};
      config.query[refferer as string] = 'mdl';
    }
    return config;
  };
  const onHeaderBackClick = () => router.push(getHeaderBackClickPath(pid as string));
  const onJobLogClick = () => updateRouteByModalRequest(ModalNames.JOB_LOG);
  const onJobLogTextSubmit = () => {
    if (jobLogInputText) {
      dispatch(jobsDuck.actions.createJobLogNote({note: jobLogInputText}));
    }
    resetInputText();
  };

  // The typing for pid indicates it may be a string[], so need to account for that possibility
  const jobId: string = (Array.isArray(jid) ? jid[0] : jid) || '';

  const showJobLogModal = Boolean(joblog);

  useEffect(() => {
    if (!showJobLogModal && jobLogInputText) resetInputText();
  }, [showJobLogModal, jobLogInputText]);

  useEffect(() => {
    if (router.isReady) {
      (async () => {
        await dispatch(jobsDuck.actions.getJobDetails());

        /* There could be deeplinking or a refresh. Make sure we have our project. */
        if (!currentProject?.id) {
          await dispatch(projectsDuck.actions.getProjectById());
        }
      })();
    }
  }, [router.isReady, dispatch]);

  /* Opens the update modal if the selected unit needs name */
  useEffect(() => {
    if (unit?.id) {
      /* If our unit is unnamed, pop open the update modal */
      if (!unit.unit_name) {
        updateRouteByModalRequest(ModalNames.UPDATE_JOB);
      }
    }
  }, [unit?.id]);

  if (!unit) return null;

  /**
   * Call out box display items. The "text" serves as a show/hide
   * Since we can have more than one callout box, express that with an iterable.
   * @type {
   *   '[BillingStatus.paid]': {header: string, theme: string, text: string},
   *   '[StatusesJob.cancelled]': {header: string, text: string},
   *   '[StatusesJob.unserviceable]': {header: string, text: string},
   *   '[StatusesJob.completed]': {header: string, text: string}}}
   */
  const callOutText = {
    [StatusesJob.completed]: {header: `Approved by ${unit.approved_by?.name}`, text: formattedCompletedDate},
    [StatusesJob.cancelled]: {header: `Cannot Complete`, text: [formattedCancelledDate, unit.cancellation_reason?.text].join('<br /><br />')},
    [StatusesJob.unserviceable]: {header: 'Unserviceable', text: [formattedUnserviceableDate, unit.unserviceable_reason?.text].join('<br /><br />')},
    [BillingStatus.paid]: {header: `Paid`, text: formattedPaidAtDate, theme: CalloutBoxThemes.SUCCESS},
  };

  const DisplayCallOuts = () => {
    const callOutTextByVariableStatus = [unit.billing_status, unit.status];
    const display = callOutTextByVariableStatus.map(status =>
      !status ? null : (
        <CalloutBox key={status} className={styles.calloutBox} header={callOutText[status]?.header} text={callOutText[status]?.text} theme={callOutText[status]?.theme || CallOutBoxStatus[status]} />
      )
    );

    return <>{display}</>;
  };

  /* Swiping Methods */
  const addToOpenSwipedItems = (serviceId: number) => {
    if (!openSwipedItems.includes(serviceId)) setOpenSwipedItems([...openSwipedItems, serviceId]);
  };
  const removeFromOpenSwipedItems = (serviceId: number) => {
    setOpenSwipedItems(openSwipedItems.filter(id => id !== serviceId));
  };

  const handleRemoveServiceClick = (service: UnitService) => {
    setSelectedService(service);
    updateRouteByModalRequest(ModalNames.REMOVE_SERVICE);
  };

  const handleFlagServiceClick = (service: UnitService, flagged = false) => {
    const flagType = flagged ? ModalNames.UNFLAG_SERVICE : ModalNames.FLAG_SERVICE;
    setSelectedService(service);
    updateRouteByModalRequest(flagType);
  };

  const handleCompleteServiceClick = async (service: UnitService) => {
    setSelectedService(service);
    const {payload} = await dispatch(jobsDuck.actions.performServiceFromJobPage({service}));
    // @ts-ignore
    if (payload?.error) {
      // @ts-ignore
      htToast.error(<NotificationOfFailedCompleteOperation {...payload.params} />, {autoClose: 5000});
    } else {
      await dispatch(jobsDuck.actions.getJobDetails());
    }
  };

  const handleSwipeProgress = (serviceId: number) => (progress: number) => {
    /*
      The entire list item is clickable, and the exposed items have their own onClick functions.
      Block the list item's onClick function if it is swiped open
    */
    const swipeIsActiveThreshold = 15;
    const isSwipedOpen = progress > swipeIsActiveThreshold;
    return isSwipedOpen ? addToOpenSwipedItems(serviceId) : removeFromOpenSwipedItems(serviceId);
  };

  const handleListItemClick = (serviceId: number) => () => {
    if (openSwipedItems.includes(serviceId)) return;
    push(servicePath(pid, jid, serviceId));
  };

  const addServices = () => {
    push(createNewServicePath(pid, jid));
  };

  const ReturnAdditionalTrailingActions = ({service}: {service: UnitService}) => {
    /* If an oder/job is unserviceable. The services are flagged, but we are unable to 'unflag'. Bone out. */
    const isUnitUnserviceable = unit.status === StatusesJob.unserviceable;
    const isServiceComplete = service.status === StatusesService.completed;
    const showFlagSwipeButton = !isUnitUnserviceable && !isServiceComplete;

    if (!showFlagSwipeButton) return null;

    return service.status === StatusesService.flagged ? (
      <SwipeButton status={service.status} key="trailingAction_unflagged" type={SwipeButtonTypes.UNFLAGGED} onButtonClick={() => handleFlagServiceClick(service, true)} />
    ) : (
      <SwipeButton status={service.status} key="trailingAction_flagged" type={SwipeButtonTypes.FLAGGED} onButtonClick={() => handleFlagServiceClick(service)} />
    );
  };

  const swipeLeftActions = (service: UnitService) => () =>
    (
      <TrailingActions>
        <SwipeButton key="trailingAction_alert" status={service.status} type={SwipeButtonTypes.ALERT} className="marginLeft-tiny1" onButtonClick={() => handleRemoveServiceClick(service)} />
        <ReturnAdditionalTrailingActions service={service} />
      </TrailingActions>
    );

  const swipeRightActions = (service: UnitService) => () => {
    const isServiceFlagged = service.status === StatusesService.flagged;

    if (isServiceFlagged) return null;

    return (
      <LeadingActions>
        <SwipeButton type={SwipeButtonTypes.COMPLETE_JOB} className="marginRight-tiny1" onButtonClick={() => handleCompleteServiceClick(service)} />
      </LeadingActions>
    );
  };

  return (
    <GridMdu.Fluid classes={styles.grid}>
      <JobDetailHeader onBackClick={onHeaderBackClick} onJobLogClick={onJobLogClick} unit={unit} jobId={jobId} />
      <div className={styles.container_services}>
        <DisplayCallOuts />
        {Array.isArray(unit?.services) && unit.services.length === 0 ? (
          <EmptyResults icon="Mobile" title="No Services on this Job">
            <Button className="marginTop-medium" theme={BUTTON_THEMES.SECONDARY} onClick={addServices}>
              Add Services
            </Button>
          </EmptyResults>
        ) : (
          <SwipeableList type={SwipeableListType.IOS}>
            {sortServices(unit?.services).map((service: UnitService) => {
              const suppressTrailingAction = StatusesJob.completed === unit.status;
              const suppressLeadingAction = service.status === StatusesService.completed;

              // return the partial function
              const SwipeLeftActionsComponent = swipeLeftActions(service);
              const SwipeRightActionsComponent = swipeRightActions(service);

              const trailingActionsProp = suppressTrailingAction ? null : SwipeLeftActionsComponent();
              const leadingActionsProp = suppressLeadingAction ? null : SwipeRightActionsComponent();
              const {sku} = service;

              return (
                <SwipeableListItem
                  maxSwipe={1}
                  trailingActions={trailingActionsProp}
                  leadingActions={leadingActionsProp}
                  key={`${unit.id}-${service.id}`}
                  onClick={handleListItemClick(service.id)}
                  className="marginBottom-small"
                  onSwipeProgress={handleSwipeProgress(service.id)}
                >
                  <Link href={`/project/${pid}/job/${jid}/service/${service.id}`} className="fullwidth">
                    <CardService key={`${service.id}${sku.id}`} iconImage={sku?.icon} status={service.status} name={sku.name} info={service?.tech?.name} />
                  </Link>
                </SwipeableListItem>
              );
            })}
          </SwipeableList>
        )}
        <StatusApprovalButton unit={unit} />
      </div>
      {/* Modals */}
      {showJobLogModal && <JobLogModalDynamic isVisible jobLogInputText={jobLogInputText} onInputChange={setInputValue(setInputText)} onTextSubmit={onJobLogTextSubmit} unit={unit} />}
      {updatejob && <UpdateJobModalDynamic unit={unit} isVisible={Boolean(updatejob)} />}
      {flagservice && <FlagServiceModalDynamic service={selectedService} isVisible={Boolean(flagservice)} />}
      {removeservice && <ServiceRemoveModalDynamic service={selectedService} isVisible={Boolean(removeservice)} />}
      {unflagservice && <UnflagServiceModalDynamic service={selectedService} isVisible={Boolean(unflagservice)} />}
      {nocomplete && <NoCompleteModalDynamic unit={unit} isVisible={Boolean(nocomplete)} />}
      {deletejob && <DeleteJobModalDynamic isVisible={Boolean(deletejob)} />}
    </GridMdu.Fluid>
  );
};

export default JobHome;
