import {createAsyncThunk, createSlice, createSelector} from '@reduxjs/toolkit';
import * as JobsTS from '@features/Jobs/jobs.types';
import * as ServicessTS from '@features/Services/services.types';
import {ErrorResponse} from '@features/Application/application.types';
import {RootState} from '@store/store';
import {applicationDuck} from '@features/Application/Application.ducks';
import {jobsDuck} from '@features/Jobs/Jobs.ducks';
import router from 'next/router';
import serviceAPIs from '@features/Services/services.api';
import {htToast} from 'ht-styleguide';
import {autoCloseToast} from '@constants/constants.base';

/*
 ************ ACTIONS
 */
export const asyncActions = {
  flagService: createAsyncThunk<ServicessTS.FlagServiceResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('service/flagService', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid, sid} = router.query;
    const serviceId = (sid || params.sid) as string;

    const serviceResponse = await serviceAPIs.flagService({project_id: pid as string, unit_id: jid as string, service_id: serviceId}, params);

    dispatch(applicationDuck.actions.setLoading(false));

    if (serviceResponse.err) {
      return rejectWithValue(serviceResponse as ErrorResponse);
    }

    // @ts-ignore
    htToast.success('Service has been flagged', {autoClose: autoCloseToast});

    /* Shared resource so lets update where needed. Internally update with reducer (below). Otherwise, dispatch */
    dispatch(jobsDuck.actions.flagService({jid, pid, ...serviceResponse}));

    return serviceResponse;
  }),
  resolveFlagService: createAsyncThunk<ServicessTS.UnflagServiceResponse, any, {rejectValue: ErrorResponse; getState: RootState}>(
    'service/resolveFlagService',
    async (params, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));

      const {pid, jid, sid} = router.query;
      const serviceId = (sid || params.sid) as string;
      const serviceResponse = await serviceAPIs.resolveFlag({project_id: pid as string, unit_id: jid as string, service_id: serviceId}, params);

      dispatch(applicationDuck.actions.setLoading(false));

      if (serviceResponse.err) {
        return rejectWithValue(serviceResponse as ErrorResponse);
      }

      // @ts-ignore
      htToast.success('Service has been unflagged', {autoClose: autoCloseToast});

      /* Shared resource so lets update where needed. Internally update with reducer (below). Otherwise, dispatch */
      dispatch(jobsDuck.actions.unflagService({jid, pid, ...serviceResponse}));

      return serviceResponse;
    }
  ),
  createService: createAsyncThunk<JobsTS.UnitWithServicesResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('service/createService', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid} = router.query;
    const {service} = params;
    const serviceResponse = await serviceAPIs.createService({project_id: pid as string, unit_id: jid as string}, {service});

    dispatch(applicationDuck.actions.setLoading(false));

    if (serviceResponse.err) {
      return rejectWithValue(serviceResponse as ErrorResponse);
    }

    // @ts-ignore
    htToast.success('Service Added', {autoClose: autoCloseToast});
    return serviceResponse;
  }),
  updateService: createAsyncThunk<JobsTS.UnitWithServicesResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('service/updateService', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid, sid} = router.query;
    const {service} = params;
    const serviceResponse = await serviceAPIs.updateService({project_id: pid as string, unit_id: jid as string, service_id: sid as string}, {service});

    dispatch(applicationDuck.actions.setLoading(false));

    if (serviceResponse.err) {
      return rejectWithValue(serviceResponse as ErrorResponse);
    }

    // @ts-ignore
    htToast.success('Service Updated', {autoClose: autoCloseToast});
    return serviceResponse;
  }),
  removeService: createAsyncThunk<JobsTS.UnitWithServicesResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('service/removeService', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid, sid} = router.query;
    const serviceId = (sid || params.sid) as string;
    const serviceResponse = await serviceAPIs.removeService({project_id: pid as string, unit_id: jid as string, service_id: serviceId});

    dispatch(applicationDuck.actions.setLoading(false));

    if (serviceResponse.err) {
      return rejectWithValue(serviceResponse as ErrorResponse);
    }

    // @ts-ignore
    htToast.success('Service Removed', {autoClose: autoCloseToast});

    /* Shared resource so lets update where needed. Internally update with reducer (below). Otherwise, dispatch */
    dispatch(jobsDuck.actions.removeService({jid, sid: serviceId}));

    return serviceResponse;
  }),
  getServiceDetails: createAsyncThunk<ServicessTS.ServiceDetailsResponse, any, {rejectValue: ErrorResponse; getState: RootState}>(
    'service/getServiceDetails',
    async (params, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));

      const {pid, jid, sid} = router.query;
      const serviceId = (sid || params.sid) as string;
      const serviceResponse = await serviceAPIs.getServiceDetails({project_id: pid as string, unit_id: jid as string, service_id: serviceId});

      dispatch(applicationDuck.actions.setLoading(false));

      if (serviceResponse.err) {
        return rejectWithValue(serviceResponse as ErrorResponse);
      }

      return serviceResponse;
    }
  ),
  performService: createAsyncThunk<ServicessTS.ServiceDetailsResponse, any, {rejectValue: ErrorResponse; getState: RootState}>(
    'service/performService',
    async (params, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));
      const {pid, jid, sid} = router.query;
      const serviceId = (sid || params.sid) as string;
      const serviceResponse = await serviceAPIs.performService({project_id: pid as string, unit_id: jid as string, service_id: serviceId}, {service: {...params.service}});

      dispatch(applicationDuck.actions.setLoading(false));

      if (serviceResponse.err) {
        return rejectWithValue(serviceResponse as ErrorResponse);
      }

      const serviceName = serviceResponse.data?.service?.sku?.name ?? 'Service';

      // @ts-ignore
      htToast.success(`${serviceName} completed`, {autoClose: autoCloseToast, delay: 500});

      return serviceResponse;
    }
  ),
  revertService: createAsyncThunk<ServicessTS.ServiceDetailsResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('service/revertService', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));
    const {pid, jid, sid} = router.query;
    const serviceId = (sid || params.sid) as string;
    const serviceResponse = await serviceAPIs.revertService({project_id: pid as string, unit_id: jid as string, service_id: serviceId});

    dispatch(applicationDuck.actions.setLoading(false));

    if (serviceResponse.err) {
      return rejectWithValue(serviceResponse as ErrorResponse);
    }

    // @ts-ignore
    htToast.success('Service reverted', {autoClose: autoCloseToast});

    return serviceResponse;
  }),
};

/*
*******************************************************
  ENTITY ADAPTORS
*******************************************************
*/

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/

export const SERVICES_INITIAL_STATE: ServicessTS.ServicesState = {
  currentService: null,
};

export const initialState = SERVICES_INITIAL_STATE;
/*
*******************************************************
  SLICE
*******************************************************
*/
const {actions: serviceActions, reducer} = createSlice({
  name: 'serviceState',
  initialState,
  reducers: {
    clearService: () => SERVICES_INITIAL_STATE,
  },
  extraReducers: builder => {
    builder
      // You'll probably need to update some services state proper here.
      .addCase(asyncActions.flagService.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.currentService = data.service;
      })
      .addCase(asyncActions.resolveFlagService.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.currentService = data.service;
      })
      .addCase(asyncActions.removeService.fulfilled, () => {})
      .addCase(asyncActions.getServiceDetails.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.currentService = data.service;
      })
      .addCase(asyncActions.performService.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.currentService = data.service;
      })
      .addCase(asyncActions.revertService.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.currentService = data.service;
      });
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getServicesState = (state: RootState) => state?.services ?? initialState;
/*

/*
 ************ EXPORTS
 */
const selectors = {
  getServicesState: createSelector(getServicesState, services => services),
  getCurrentService: createSelector(getServicesState, (services): ServicessTS.ServiceDetails => services.currentService),
};

export const servicesDuck = {
  actions: {...serviceActions, ...asyncActions},
  reducer,
  initialState,
  selectors,
};
