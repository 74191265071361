import Cookies from 'js-cookie';

const PROJECT_TECH_PROMPT_MODAL = '_project_tech_prompt_modal';

export const setProjectTechPromptModalCookie = (pid: string, seen = '') => {
  const currentCookie = JSON.parse(Cookies.get(PROJECT_TECH_PROMPT_MODAL) || '{}');

  Cookies.set(PROJECT_TECH_PROMPT_MODAL, JSON.stringify({...currentCookie, [pid]: seen}));
};

export const getProjectTechPromptModalCookie = (pid: string) => {
  const value = Cookies.get(PROJECT_TECH_PROMPT_MODAL);
  return value ? JSON.parse(value)?.[pid] : null;
};
