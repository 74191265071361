import React from 'react';
/* Components & Styles */
import {Icon} from 'ht-styleguide';

export const backArrowTestId = 'HeaderBackButtonAndText-BackArrow';

type HeaderBackButtonAndTextProps = {
  onBackArrowClick: () => void;
  headerText?: string;
};

// eslint-disable-next-line arrow-body-style
const HeaderBackButtonAndText = ({onBackArrowClick = () => {}, headerText = ''}: HeaderBackButtonAndTextProps) => {
  return (
    <header className="flex flexDirectionColumn gridSidePadding paddingY-small">
      <Icon name="Arrow" className="icon-xlarge n700 marginBottom-small" onClick={onBackArrowClick} dataTestId={backArrowTestId} />
      <h1 className="h4 marginBottom-tiny1 n900">{headerText}</h1>
    </header>
  );
};

export default HeaderBackButtonAndText;
