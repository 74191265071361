import {useEffect} from 'react';
import {AppProps} from 'next/app';
import {wrapper} from '@store/store';
import {useRouter} from 'next/router';
import {useDispatch, useSelector} from 'react-redux';
import MduBugsnag from '@integrations/bugsnag';

/* Global: scss */
import '@styles/globals.scss';
import '@public/fonts/fonts.css';

/* ducks */
import {applicationDuck} from '@features/Application/Application.ducks';

/* components */
import Notification, {notify} from '@components/Notification';
import {LoaderPage} from 'ht-styleguide';

/* Types */
import {NotifyType} from '@features/Application/application.types';

export interface IMyAppProps extends AppProps {
  Component: any;
  dehydratedState: any;
  pageProps: any;
}

const ErrorBoundary = MduBugsnag();
const MyApp = ({Component, pageProps}: IMyAppProps) => {
  const {type, message, id = ''} = useSelector(applicationDuck.selectors.notificationSelector);
  const {loading} = useSelector(applicationDuck.selectors.applicationSelector);
  const dispatch = useDispatch();
  const router = useRouter();

  /**
   * This is the global notification. On close it will clear it.
   * We only allow 1 Notification at once.
   */
  if (type && message) {
    const t = type as NotifyType;
    if (!notify.isActive(id)) {
      notify[t](message, {
        toastId: id,
        onClose: () => dispatch(applicationDuck.actions.removeNotificationError()),
      });
    }
  }

  const handleModalBodyInteraction = (url: string) => {
    if (url.includes('mdl')) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleModalBodyInteraction);
    return () => {
      router.events.off('routeChangeComplete', handleModalBodyInteraction);
    };
  }, [router.events]);

  return (
    <ErrorBoundary FallbackComponent={() => <div>Error Page</div>}>
      <div className="appContainer">{Component.Layout ? Component.Layout(<Component {...pageProps} />) : <Component {...pageProps} />}</div>
      <Notification />
      {loading && <LoaderPage className="page_loader" />}
    </ErrorBoundary>
  );
};

export default wrapper.withRedux(MyApp);
