import {IHash} from '@features/Application/application.types';
import {EntityState} from '@reduxjs/toolkit';

export enum QuestionTypes {
  Dropdown = 'dropdown',
  Checkbox = 'checkbox',
  Textarea = 'textarea',
  Input = 'input',
  Device = 'device',
}

export type DevicePreQuestionAnswer = {
  product: number;
  product_id?: number;
  product_make: number;
  product_make_id?: number;
  product_make_text?: string;
  product_text?: string;
};

export type LegacyPreQuestion = {
  id: number;
  answer?: string | DevicePreQuestionAnswer; // Text field input or Device question
  answers?: Answer[]; // Dropdowns, Checkboxes, etc.
  input_type: QuestionTypes;
  inputType: QuestionTypes;
  is_ready: boolean;
  pre_answer: string | null; // Text field input
  pre_answers: PreAnswer[] | null; // Dropdowns, Checkboxes, etc.
  required: boolean;
  text: string;
  make_text: string;
  product_make: number;
  hint: string | null;
  serviceId: number | null;
  text_direct: any;
};

export type PreAnswer = {
  action: any; // TODO: Properly type this
  adj_amount: number;
  adj_cut: number;
  adj_description: string | null;
  default: boolean;
  has_quantity: boolean;
  pre_answer_id: number;
  pre_question_id: number;
  quantity: number;
  text: string | null;
};

export type Partner = {
  partner_id: number;
  name: string;
} | null;

export type SkuBase = {
  id: number;
  name: string;
};

export type Sku = {
  skuName: string;
  hasScopeOfWork: boolean;
  questions: LegacyPreQuestion[];
  preQuestions?: LegacyPreQuestion[];
  siteDataQuestions?: SiteDataQuestion[];
  subsidizingBasePriceOnly: boolean;
} & SkuBase;

export type MduTechApiSku = {
  icon: {
    id: number;
    name: string;
  };
} & SkuBase;

export type SkusSortedByPartnerId = {
  id: number; // partner id
  skus: MduTechApiSku[];
};

/* Response Types */
export type GetPartnerSkusResponse = {
  partnerId: number;
  data: {
    skus: MduTechApiSku[];
  };
};

export type GetSkuByIdResponse = {
  data: Sku;
};

/* QA Types taken from New Admin */

export type InputType = QuestionTypes;

export type QAErrors = IHash<string> | IHash<IHash<string>>;
export type ErrorHashProp = IHash<string>;

type BaseQuestion = {
  id: number | string;
  input_type?: InputType;
  hint: string | null;
  text_direct: string;
  answer: Array<Answer | ModelAnswer> | ModelAnswer;
  answers: Array<Answer | ModelAnswer>;
  pre_answers: {
    answers: Array<Answer | ModelAnswer>;
  };
  text: string;
};

export type QuestionsAPIByQuestion = BaseQuestion & {
  required: boolean;
  serviceId: number | null;
  product: number;
  make_text: string | null;
  product_text: string | null;
};

export type SiteDataQuestion = BaseQuestion & {
  index: number;
  active: boolean;
  sku_id: number | null;
};

export type Answer = {
  answers: any[];
  adjAmount: number;
  adjAmountFormatted: AdjAmountFormatted | string | null;
  adjDescription: string;
  default: boolean;
  hasQuantity: boolean;
  has_quantity: boolean;
  id: number | string;
  text: string;
  warning: string | null;
  forbidRemote?: boolean;
  subsidizedToPartner?: number;
  pre_answer_id: number | string;
  quantity?: number;
};

export type ProductQuestion = {
  id: number;
  text: string;
  answers: {
    id: number;
    name: string;
  }[];
};

export type ModelAnswer = Answer & {
  product_question: ProductQuestion;
  productQuestion: ProductQuestion;
};

export enum CustomDropdownValues {
  negOne = -1,
  empty = '',
  zero = 0,
}

export type AdjAmountFormatted = {
  formatted: string;
  value: number;
};

export type QuestionDeviceProps = {
  question: GenericQuestion;
  sku: Sku;
  error: ErrorHashProp;
  value: string | number;
  modelValue: number | string;
  makeInputValue: string | null | undefined;
  modelInputValue: number | string;
  onMakeDropdownChange: BaseAnyFunction;
  onModelDropdownChange: BaseAnyFunction;
  onMakeInputChange: BaseAnyFunction;
  onModelInputChange: BaseAnyFunction;
  onIconClick: BaseAnyFunction;
};

export type SelectedSkuQuestions = {
  [key: string]: any;
};

export type SelectedSku = {
  autoApplyCoupon: boolean | null;
  totalPrice: string;
  startsAtPrice: string | null;
  preQuestionAnswers: SelectedSkuQuestions;
  siteDataQuestions: SiteDataQuestion[];
  partner: Partner;
  name: string;
  overrideBasePrice: number | null;
  skuId: number;
  lens: boolean;
  category: string;
  quantity: number;
} & Sku;

export type CheckBoxValue = Array<{
  id: number;
  quantity?: number;
}>;

export interface State {
  questions: EntityState<any>;
  skusAvailableByPartnerId: EntityState<any>;
  skuDetails: EntityState<any>;
  selectedSku: SelectedSku;
  errors: QAErrors;
}

export type QuestionsAPI = {
  data: {
    sku: {
      id: number;
      name: string;
      pre_questions: QuestionsAPIByQuestion[];
      site_data_questions: SiteDataQuestion[];
    };
  };
};

export type EntityQuestions = {
  id: number | string;
  skuName: string;
  questions: QuestionsAPIByQuestion[];
  siteDataQuestions: SiteDataQuestion[];
};

export type CreateServicePreQuestionAnswersRequest = {
  preQuestionId: number;
  answer?: any;
  answers?: any[];
};

export type SelectedSkuDeviceAnswer = {
  answerId: number | null;
  answerValue: number;
  make: string;
  model: number;
};

export type GenericQuestion = QuestionsAPIByQuestion | SiteDataQuestion;
