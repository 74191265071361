import {Icon, ELEMENT_SIZE} from 'ht-styleguide';
import cn from 'classnames';
import styles from './empty.module.scss';

export type EmptyResultsProps = {
  icon: string;
  title: string;
  children?: React.ReactElement;
  size?: ELEMENT_SIZE.LARGE | ELEMENT_SIZE.MEDIUM;
};

const EmptyResults = ({icon, title, children, size = ELEMENT_SIZE.MEDIUM}: EmptyResultsProps) => {
  const iconSizeClass = styles[`icon_${size}`];

  return (
    <div className={styles.container}>
      <div className={styles.callout}>
        <div className={cn(styles.callout_icon, iconSizeClass)}>
          <Icon name={icon} />
        </div>
      </div>
      <p className="h4 marginTop-medium text-align-center">
        {title}
        {children || null}
      </p>
    </div>
  );
};

export default EmptyResults;
