import {configureStore, combineReducers, ThunkAction, Action, ThunkDispatch} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {createWrapper} from 'next-redux-wrapper';

/* Middleware */
import bugsnagMiddleware from '@integrations/bugsnag/bugsnag.middleware';
import notificationMiddleware from '@store/middleware/notifications';
import authenticationMiddleware from '@store/middleware/authenticationValidation';

/* Reducers */
import {jobsDuck} from '@features/Jobs';
import {projectsDuck} from '@features/Projects';
import {servicesDuck} from '@features/Services';
import {skusDuck} from '@features/Skus';
import {userDuck} from '@features/User/User.ducks';
import {applicationDuck} from '@features/Application/Application.ducks';

/* Lets break the circular dependency */
export const rootReducer = combineReducers({
  user: userDuck.reducer,
  projects: projectsDuck.reducer,
  jobs: jobsDuck.reducer,
  services: servicesDuck.reducer,
  skus: skusDuck.reducer,
  application: applicationDuck.reducer,
});

/* This must be fixed soon. Getting a circular ts error when using "typeof rootReducer" */
export type RootState = ReturnType<$FixMe>;

function makeStore() {
  return configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: true,
      }).prepend(authenticationMiddleware, notificationMiddleware, bugsnagMiddleware),
  });
}

/* Store Types */
type Store = ReturnType<typeof makeStore>;
export type AppDispatch = ThunkDispatch<RootState, unknown, Action>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const wrapper = createWrapper<Store>(makeStore);
