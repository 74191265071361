import {createSelector, createSlice} from '@reduxjs/toolkit';
import {AppState} from '@features/Application/application.types';
import {RootState} from '@store/store';

/**
 *
 */
const initialState: AppState = {
  loading: false,
  notification: {
    type: '',
    message: '',
    level: 'page',
    id: '',
  },
};

const {actions, reducer} = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    removeNotificationError: state => {
      state.notification = {
        ...state.notification,
        type: '',
        message: '',
        level: '',
        id: '',
      };
    },
    notificationApiPending: state => {
      state.notification = {
        ...state.notification,
        type: '',
        message: '',
        level: 'page',
      };
    },
    notificationApiError: (state, action) => {
      const {source = 'Api Error'} = action.payload;
      state.notification = {
        ...state.notification,
        type: 'error',
        message: source,
        level: 'page',
        id: source.replace(/\s/g, ''),
      };
    },
  },
});

/*
*******************************************************
  EXPORTS
*******************************************************
*/
const getAppSelector = (state: RootState) => state?.application;
export const selectors = {
  applicationSelector: createSelector(getAppSelector, application => application),
  loadingSelector: createSelector(getAppSelector, application => application?.loading || false),
  notificationSelector: createSelector(getAppSelector, application => application.notification),
};

export const applicationDuck = {
  actions,
  reducer,
  selectors,
};
