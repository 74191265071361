import React from 'react';
import cn from 'classnames';
import {noop} from '@utils/event';
import {Children} from '@features/Application/application.types';
import styles from '@features/Projects/Parts/Header/header.module.scss';

const Container = ({children}: {children: Children}) => <div className="flex">{children}</div>;

type TabProps = {
  text: string;
  isActive: boolean;
  onClick: () => void;
};

const Tab = ({text, isActive, onClick = noop}: TabProps) => {
  const tabStyles = cn(styles.headerTab, 'plainButton', {
    [styles.active]: isActive,
  });
  return (
    <button type="button" onClick={onClick} className={cn(tabStyles)}>
      {text}
    </button>
  );
};

const HeaderTabs = {Container, Tab};

export default HeaderTabs;
