import {useAppSelector} from '@store/store';
import {skusDuck} from '@features/Skus';
import {QuestionsAPIByQuestion, SiteDataQuestion} from '@features/Skus/skus.types';
import {extractSiteDataAnswers} from '@features/Skus/skus.utils';
import {ServiceDetails} from '@features/Services/services.types';

export const formatSiteDataQA = ({skuSiteDataQuestions, answers}: {skuSiteDataQuestions: SiteDataQuestion[]; answers: {}}) =>
  (skuSiteDataQuestions || []).map(
    q =>
      ({
        id: q.id,
        answer: answers[q.id]?.text || '',
        input_type: q.input_type,
        text: q.text_direct,
      } as QuestionsAPIByQuestion)
  );

export type ServiceSiteDataBlockProps = {
  skuId: number;
  service?: ServiceDetails;
  isServiceCompleted?: boolean;
};

export const useSiteDataAnsweredQuestions = ({service, skuId, isServiceCompleted = false}: ServiceSiteDataBlockProps) => {
  const questionEntities = useAppSelector(skusDuck.selectors.getQuestionsEntities);
  const skuSiteDataQuestions: SiteDataQuestion[] = questionEntities[String(skuId)]?.siteDataQuestions;
  const selectedSku = useAppSelector(skusDuck.selectors.getSelectedSku);

  const siteDataAnswers = extractSiteDataAnswers(service);

  const answers = isServiceCompleted ? siteDataAnswers : selectedSku.siteDataQuestions;

  return formatSiteDataQA({skuSiteDataQuestions, answers});
};
