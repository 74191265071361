import Bugsnag from '@bugsnag/js';

function removeNonValues<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const makePath = (path: string) => {
  if (!path.includes(':')) {
    return () => path;
  }
  return function replaceParams(...args: any[]) {
    if (args.includes(null)) {
      Bugsnag?.notify(new Error(`Error in makePath: null args. ${path} ${args}`));
    }

    const result = args.filter(removeNonValues).reduce((link, arg) => link.replace(/:\w+/, (arg || '0').toString()), path);

    if (result.includes(':')) {
      throw new Error(`Please provide all replacement for a path: ${path} - ${result}`);
    }

    return result;
  };
};

// Base Paths
export const homePath = makePath('/');
// export const resetPasswordPath = makePath('/reset-password');

// Project Paths
export const projectHomePath = makePath('/project');
export const projectListPath = makePath('/project/:pid');

// Job Paths
export const jobPath = makePath('/project/:pid/job/:id');

// Service Paths
export const servicePath = makePath('/project/:pid/job/:jid/service/:sid');
export const createNewServicePath = makePath('/project/:pid/job/:jid/create-service');
export const editServicepath = makePath('/project/:pid/job/:id/service/:sid/edit-sku/:skuid');

// Sku Paths
export const addSkuToJobPath = makePath('/project/:pid/job/:jid/add-sku/:skuid');

// External Links / Reset Password - Tech Dashboard
export const dashBoardGeek = 'https://admin.hellotech.com/password/new?as=geek';
