import React from 'react';
import cn from 'classnames';
import {IGrid, IColumnGrid} from './GridMdu.types';

const FluidGrid: React.FC<IGrid> = ({children, classes = '', dataTestId = 'StyleGuideFluidGrid'}) => {
  const fluidGridStyles = cn('grid', classes);
  return (
    <div data-testid={dataTestId} className={fluidGridStyles}>
      {children}
    </div>
  );
};

const FullWidthGrid: React.FC<IGrid> = ({children, classes = '', dataTestId = 'StyleGuideFullGrid'}) => {
  const fullWidthGridStyles = cn('grid-full-width', classes);
  return (
    <div data-testid={dataTestId} className={fullWidthGridStyles}>
      {children}
    </div>
  );
};

const Row: React.FC<IGrid> = ({children, classes = ''}) => {
  const rowStyles = cn('row', classes);
  return <div className={rowStyles}>{children}</div>;
};

const Column: React.FC<IColumnGrid> = ({children, classes = '', sm, smOffset}) => {
  const smStyles = sm && `col-sm-${sm}`;
  const smOffsetStyles = Number.isInteger(smOffset) && `col-sm-offset-${smOffset}`;
  const genericColumn = !sm && 'col'; // .col is styled with flex:1
  const columnStyles = cn({
    [smStyles as any]: Boolean(smStyles),
    [smOffsetStyles as any]: Boolean(smOffsetStyles),
    [genericColumn as any]: Boolean(genericColumn),
    [classes as any]: Boolean(classes),
  });
  return <div className={columnStyles}>{children}</div>;
};

const Grid = {
  Fluid: FluidGrid,
  FullWidth: FullWidthGrid,
  Row,
  Column,
};

export default Grid;

/*
  This is a derivative work of the <Grid/> component we have in ht-styleguide. Per Design this app is supposed to be
  mobile first/only, and has no need for tablet or desktop specific grid styling
*/
