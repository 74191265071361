import React from 'react';
import cn from 'classnames';
import {SwipeAction} from 'react-swipeable-list';
import {Icon} from 'ht-styleguide';
import {ICON_LABELS, ICON_NAMES, swipeButtonDataTestId} from './constants';
import styles from './swipeButton.module.scss';

export enum SwipeButtonTypes {
  SUCCESS,
  ALERT,
  FLAGGED,
  UNFLAGGED,
  UNCANCEL,
  CANCEL,
  COMPLETE_JOB,
}

export const getIconNameAndLabel = (type: SwipeButtonTypes) => {
  const mapping = {
    [SwipeButtonTypes.SUCCESS]: {iconName: ICON_NAMES.SUCCESS, label: ICON_LABELS.SUCCESS},
    [SwipeButtonTypes.COMPLETE_JOB]: {iconName: ICON_NAMES.SUCCESS, label: ICON_LABELS.COMPLETE_JOB},
    [SwipeButtonTypes.ALERT]: {iconName: ICON_NAMES.ALERT, label: ICON_LABELS.ALERT},
    [SwipeButtonTypes.FLAGGED]: {iconName: ICON_NAMES.FLAGGED, label: ICON_LABELS.FLAGGED},
    [SwipeButtonTypes.UNFLAGGED]: {iconName: ICON_NAMES.UNFLAGGED, label: ICON_LABELS.UNFLAGGED},
    [SwipeButtonTypes.UNCANCEL]: {iconName: ICON_NAMES.UNCANCEL, label: ICON_LABELS.UNCANCEL},
    [SwipeButtonTypes.CANCEL]: {iconName: ICON_NAMES.CANCEL, label: ICON_LABELS.CANCEL},
  };
  return mapping[type] || {icon: '', label: ''};
};

const SwipeButton = ({status, type, className, iconClass, onButtonClick}: {status?: string; type: SwipeButtonTypes; iconClass?: string; className?: string; onButtonClick?: () => void}) => {
  const containerStyles = cn(styles.button_container, className, {
    [styles.button_container_success]: [SwipeButtonTypes.SUCCESS, SwipeButtonTypes.COMPLETE_JOB].includes(type),
    [styles.button_container_alert]: type === SwipeButtonTypes.ALERT,
    [styles.button_container_flagged]: type === SwipeButtonTypes.FLAGGED,
    [styles.button_container_unflagged]: type === SwipeButtonTypes.UNFLAGGED,
    [styles.button_container_uncancel]: type === SwipeButtonTypes.UNCANCEL,
    [styles.button_container_cancel]: type === SwipeButtonTypes.CANCEL,
  });

  const iconContainerStyles = cn(styles.icon_container, {
    [styles.icon_container_alert]: type === SwipeButtonTypes.ALERT,
    [styles.icon_container_uncancel]: type === SwipeButtonTypes.UNCANCEL,
  });

  const iconStyles = cn(
    styles.icon,
    {
      [styles.icon_success]: [SwipeButtonTypes.SUCCESS, SwipeButtonTypes.COMPLETE_JOB].includes(type),
      [styles.icon_alert]: type === SwipeButtonTypes.ALERT,
      [styles.icon_flagged]: type === SwipeButtonTypes.FLAGGED,
      [styles.icon_unflagged]: type === SwipeButtonTypes.UNFLAGGED,
      [styles.icon_uncancel]: type === SwipeButtonTypes.UNCANCEL,
      [styles.icon_uncancel]: type === SwipeButtonTypes.CANCEL,
    },
    iconClass
  );

  const {iconName, label} = getIconNameAndLabel(type);

  return (
    <SwipeAction onClick={onButtonClick!}>
      <button type="button" className={containerStyles} data-testid={`${swipeButtonDataTestId}-${type}-${status}`}>
        <div className={iconContainerStyles}>
          <Icon name={iconName} className={iconStyles} />
          <p className={cn(styles.icon_label, 'label')}>{label}</p>
        </div>
      </button>
    </SwipeAction>
  );
};

export default SwipeButton;

// This is intended to be used as the button that is exposed when a Card is swiped to the left or right
