import {CalloutBoxThemes} from 'ht-styleguide';

/* base level site wide constants */

/* ------------------------------------------------- */
/* ---------------- ENVIRONMENT --------------------- */
/* ------------------------------------------------- */
export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const DEVELOPMENT = 'development';

/* ------------------------------------------------- */
/* ---------------- STATUSES ----------------------- */
/* ------------------------------------------------- */
/* SERVER RESPONSES ERRORS */
export const StatusErrors = new Map([
  [400, 400],
  [404, 404],
  [500, 500],
]);

export const StatusSuccess = new Map([[200, 200]]);

// Map Statuses to theme calloutbox statuses
export const CallOutBoxStatus = {
  cancelled: CalloutBoxThemes.CRITICAL,
  unserviceable: CalloutBoxThemes.CRITICAL,
  completed: CalloutBoxThemes.PRIMARY,
  not_started: CalloutBoxThemes.DEFAULT,
  pending_approval: CalloutBoxThemes.DEFAULT,
  in_progress: CalloutBoxThemes.DEFAULT,
  flagged: CalloutBoxThemes.CRITICAL,
  paid: CalloutBoxThemes.SUCCESS,
};
/* ---------------- END STATUSES ----------------------- */

/* API RESPONSES */
export const REJECTED = 'rejected';
export const PENDING = 'pending';
export const FULFILLED = 'fulfilled';

/* -------------------------------------------------------- */
/* ------------ FORM FIELD ERRORS & MISC ------------------ */
/* -------------------------------------------------------- */
export const FieldErrorEmpty = 'Field cannot be empty';
export const DEFAULT_SELECT_OPTION = {value: '', label: 'Select a reason'};

/* AutoClose for toasts */
export const autoCloseToast = 1500;

/* -------------------------------------------------------- */
/* ------------ DateTime ------------------ */
/* -------------------------------------------------------- */

export const TIMEZONES = {
  AMERICA: {
    LOS_ANGELES: 'America/Los_Angeles',
  },
};

/* Date & Time Format */
export const shortDayMonthWithYearTime = 'ddd, MMM D, YYYY h:mm A'; // Thu, Aug 16, 2018 8:02 PM
export const shortMonthWithDay = 'MMM D, YYYY'; // Jan 7, 2022
export const shortDayMonthWithYear = 'ddd, MMM D, YYYY'; // Thu, Aug 16, 2018
