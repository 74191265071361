import React from 'react';
import cn from 'classnames';
import {Icon, InputField} from 'ht-styleguide';
import styles from './searchInput.module.scss';

type SearchInputProps = {
  inputIconName?: string;
  inputIconClass?: string;
  inputRef?: React.MutableRefObject<any>;
  inputPlaceholder?: string;
  onInputChange?: BaseAnyFunction;
  onInputIconClick?: BaseAnyFunction;
  onInputFocus?: BaseAnyFunction;
  onInputBlur?: BaseAnyFunction;
  inputContainerClass?: string;
  inputWrapperClass?: string;
  dataTestId?: string;
};

const SearchInput = ({
  inputIconName,
  inputIconClass = 'blue700',
  inputRef,
  inputPlaceholder,
  onInputChange,
  onInputIconClick,
  onInputFocus,
  onInputBlur,
  dataTestId = 'keyword-search',
  inputContainerClass,
  inputWrapperClass,
}: SearchInputProps) => (
  <div className={styles.container}>
    <Icon name="Search" className="paddingLeft-tiny" />
    <InputField
      dataTestId={dataTestId}
      ref={inputRef}
      containerClass={cn(inputContainerClass, styles.input_override)}
      inputWrapperClass={(cn(inputWrapperClass), styles.input_override)}
      onChange={onInputChange}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      iconName={inputIconName}
      iconClass={inputIconClass}
      iconOnClick={onInputIconClick}
      placeholder={inputPlaceholder}
    />
  </div>
);

export default SearchInput;
