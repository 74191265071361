import React from 'react';
import {Icon} from 'ht-styleguide';
import {BaseAnyFunction} from '@features/Application/application.types';
import styles from './notification.module.scss';

// eslint-disable-next-line react/require-default-props
const NotificationCloseIcon = ({closeToast}: {closeToast?: BaseAnyFunction}) => {
  if (!closeToast) return null;

  return <Icon name="v2-close-icon" className={styles.close} onClick={closeToast} />;
};

export default React.memo(NotificationCloseIcon);
