import {PRODUCTION, STAGING, DEVELOPMENT} from '@constants/constants.base';

export const isServerSide = () => typeof window === 'undefined';

export const isBrowser = () => typeof window !== 'undefined';

export const isProduction = () => process.env.NEXT_PUBLIC_NODE_ENV === PRODUCTION;

export const isStaging = () => process.env.NEXT_PUBLIC_NODE_ENV === STAGING;

export const isDevelopment = () => process.env.NEXT_PUBLIC_NODE_ENV === DEVELOPMENT;

export const getEnvironment = () => process.env.NEXT_PUBLIC_NODE_ENV || PRODUCTION;
