import {QuestionsAPIByQuestion, QuestionTypes, SelectedSku} from '@features/Skus/skus.types';
import {ServiceDetails} from '@features/Services/services.types';

type IsServiceReadyToSubmitArgs = {
  currentService: ServiceDetails;
  selectedSku: SelectedSku;
  siteDataQuestions: QuestionsAPIByQuestion[];
};
/** Handles the FE validation of Device and Onsite Questions that allows the user to complete a service */
export const isServiceReadyToSubmit = ({currentService, selectedSku, siteDataQuestions}: IsServiceReadyToSubmitArgs) => {
  /*
    Validate Device Questions
      - null or empty string values for device questions indicate a missing answer, either by a selecting freeform input and not
        entering text (empty string), or not making any selection at all (null).
      - The BE has the option to validate Device questions, but per design the FE should validate 100% of the time
  */
  const deviceQuestions = currentService?.pre_questions.filter(pq => pq.input_type === QuestionTypes.Device);
  const deviceQuestionReduxValues = selectedSku?.preQuestionAnswers[deviceQuestions && deviceQuestions[0]?.id];
  const isMakeAnswered = Boolean(deviceQuestionReduxValues && ![null, ''].includes(deviceQuestionReduxValues?.answerValue));
  const isModelAnswered = Boolean(deviceQuestionReduxValues && ![null, ''].includes(deviceQuestionReduxValues?.model));
  // Validate Site Data Questions
  const isSiteDataReady = siteDataQuestions.every(question => question.answer);
  return (deviceQuestions?.length ? isMakeAnswered && isModelAnswered : true) && isSiteDataReady;
};
