import Bugsnag from '@bugsnag/js';

export const logOutSideError = ({err}: {err: string}) => {
  if (!window?.navigator?.onLine) {
    setTimeout(logOutSideError, 100, `Offline: ${err}`);
  } else {
    const error = new Error(err);
    Bugsnag.notify(error);
  }
};
