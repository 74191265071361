import {useRouter} from 'next/router';
import {useAppSelector} from '@store/store';
import {projectsDuck} from '@features/Projects';
import {PermissionRoles} from '@components/Permission/permission.types';

/**
 * Placeholder Permissions Component till we know what the
 * permissions will look like - Design is getting it together.
 *
 * @param {React.ReactElement} Component
 * @constructor
 */

type PermissionProps = {
  children: React.ReactElement;
  level?: PermissionRoles | PermissionRoles[];
};

/**
 * Permission HOC.
 * If you pass in an array, then its gardened by specific types.
 * If you pass just the enum, then you are permissiable by >= level passed
 *
 * ex: if you pass (or not as its default) PermissionRoles.lead_tech, then tech_lead and any
 *     permission above it (admin) is allowed.
 *
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>>} children
 * @param {PermissionRoles | PermissionRoles[]} level
 * @returns {ReactNode}
 * @constructor
 */
const Permission = ({children, level = PermissionRoles.lead_tech}: PermissionProps): JSX.Element | null => {
  const {
    query: {pid},
  } = useRouter();
  const project = useAppSelector(projectsDuck.selectors.getCurrentProject(pid as string));

  /* Create permission bundle */
  const permissions = ([] as number[]).concat(level);
  const deriveValidPermission = (): boolean => {
    const {current_role} = project?.current_user ?? {};

    /* Test by inclusion */
    if (Array.isArray(level)) {
      if (permissions.includes(Number(PermissionRoles[current_role]))) {
        return true;
      }
    } else if (Number(PermissionRoles[current_role]) <= level) {
      return true;
    }

    return false;
  };

  if (!deriveValidPermission()) return null;

  return <>{children}</>;
};

export default Permission;
