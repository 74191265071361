import {PermissionRoles} from '@components/Permission/permission.types';

/**
 * These are not the project.status value, but a a string value that the BE uses to run SQL queries
 * E.g., "completed" is a valid project.status and a filter here, but "not_completed" is not a valid
 * value for project.status. See app/models/project.rb in Rails project for details.
 */
export enum StatusesProjectFilter {
  completed = 'completed',
  not_completed = 'not_completed',
}

/**
 * Values used for endpoints and UI
 *
 * `only_paused` - Only show paused projects
 * `only_not_paused` - Only show projects that are not paused
 *  rest - projects regardless of paused status
 *
 * Pausing action is only allowed for in progress, upcoming, and needs approval project statuses
 */
export enum PausedStatusTypes {
  ONLY_PAUSED = 'only_paused',
  ONLY_NOT_PAUSED = 'only_not_paused',
  ALL = '',
}

export type PaidUnit = {id: number; amount: number; amount_formatted: string; unit_name: string};

export type ProjectUnitPayment = {
  date: string;
  amount: number;
  amount_formatted: string;
  units: PaidUnit[];
};

export type ProjectPaymentDetails = {
  paid_amount: number;
  paid_amount_formatted: string;
  paid_units: number;
  next_payment_amount: number;
  next_payment_amount_formatted: string;
  next_payment_date: string | null;
  payments: ProjectUnitPayment[];
};

/* Individual small project return. For list */
export type ProjectBase = {
  id: number;
  name: string;
  units_total: number;
  floors_number: number;
  completed_units_total: number;
  unserviceable_units_total: number;
  open_units_total: number;
  completed_units_percent: number;
  unserviceable_units_percent: number;
  open_units_percent: number;
  start_date: string;
  address_obj: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  paused: boolean;
};

/* List of the base project */
export type ProjectBaseList = ProjectBase[];

/* Full individual project */
export type ProjectFull = ProjectBase & {
  start_date: string;
  end_date: string | null;
  estimated_end_date: string;
  status: MDUStatuses;
  contact_name: string;
  contact_phone: string;
  contact_title: string;
  access_instructions: string;
  notes: string;
  address: string;
  current_user: {
    real_role: keyof typeof PermissionRoles;
    current_role: keyof typeof PermissionRoles;
  };
  lead_tech: {
    id: number;
    name: string;
  };
  billing_lead: {
    id: number;
    name: string;
  };
  techs: Tech[];
  techLead: Tech[];
  partner: {
    id: number;
    name: string;
  };
  client: {
    id: number;
    name: string;
  };
  project_groups: ProjectGroup[];
  paymentDetails?: ProjectPaymentDetails;
};

export type ProjectGroup = {
  id: number;
  name: string;
  project_services: ProjectServices[];
};

export type ProjectServices = {
  id: number;
  quantity: number;
  sku: {
    id: number;
    name: string;
  };
};

export type Tech = {
  email: string;
  id: number;
  lead_tech?: boolean;
  name: string;
  phone: string;
  profile_picture: {
    thumb: string;
  };
};

/* Projects List Payload */
export type ProjectListResponsePayload = {
  data: {
    projects: ProjectBaseList;
  };
};

/* Single Projects Payload */
export type ProjectFullResponsePayload = {
  data: {
    project: ProjectFull;
  };
};

/* Techs Response from adding/removing */
export type TechsResponsePayload = {
  data: {
    techs: Tech[];
  };
};

/* Payment Details Response */
export type PaymentDetailsPayload = {
  data: {
    result: ProjectPaymentDetails & {
      project: {
        id: number;
        name: string;
      };
    };
  };
};

/* Projects State */
export type ProjectsState = {
  projects: ProjectBaseList;
  project: ProjectFull;
  projectsByType: {
    [key in SearchTypes]: {
      projects: [];
    };
  };
  searchTerm: string;
  searchType: SearchTypes;
  /**
   * Params directly passed to projects list endpoint
   */
  searchFilters: {
    statuses: StatusesProjectFilter[];
    paused: PausedStatusTypes;
  };
};

/* Modal Names */
export enum ModalNames {
  JOB_LOG = 'joblog',
  REMOVE_SERVICE = 'removeservice',
  FLAG_SERVICE = 'flagservice',
  UNFLAG_SERVICE = 'unflagservice',
  NO_COMPLETE = 'nocomplete',
  UPDATE_JOB = 'updatejob',
  ADD_TEAM = 'addteam',
  PROJECT_INFO = 'projectinfo',
  LIST_TEAM = 'listteam',
  CREATE_JOB = 'createjob',
  FILTER = 'filter',
  START_JOB = 'startjob',
  DELETE_JOB = 'deletejob',
  PAYMENT_DETAILS = 'paymentdetails',
}

/* Enum Search Types */
export enum SearchTypes {
  keyword = 'keyword',
  status = 'status',
}

/** This enum is a copy of what you'll find in ht-admin project */
export enum MDUStatuses {
  /** Initial state, requirements missing */
  DRAFT = 'draft',
  /** All requirements satisfied */
  READY = 'ready_to_launch',
  /** After launch, tech didn't start any units yet. */
  UPCOMING = 'upcoming',
  /**  After launch, tech has already started at least one uni */
  IN_PROGRESS = 'in_progress',
  /** After launch, All units completed */
  NEEDS_APPROVAL = 'needs_approval',
  /** All units completed and lead tech has already approved the project */
  APPROVED = 'approved',
}
