import React from 'react';
import {Children} from '@features/Application/application.types';
import styles from './menu.module.scss';

type MenuLineItemCalloutGroupProps = {
  children: Children;
};

const MenuLICallout = ({children}: MenuLineItemCalloutGroupProps) => <div className={styles.callout_group}>{children}</div>;

export default MenuLICallout;
