import {Tech} from '@features/Projects/projects.types';
import {QuestionsAPIByQuestion} from '@features/Skus/skus.types';

export enum StatusesService {
  not_started = 'not_started',
  completed = 'completed',
  flagged = 'flagged',
}

export type ServiceFlag = {
  created_at: string;
  description: string;
  reason: string;
};

type SiteDataQuestion = {
  question: string;
};

type SiteDataAnswer = {
  answer: string;
  site_data_question_id: number;
};

export type ServiceDetailsQuestions = {
  id: number;
  pre_questions: QuestionsAPIByQuestion[];
  additional_information_question: string | null;
  site_data_questions: SiteDataQuestion[];
};

export type ServiceDetails = {
  id: number;
  pre_questions: QuestionsAPIByQuestion[];
  additional_information_answer: string | null;
  site_data_answers: SiteDataAnswer[];
  service_flag: ServiceFlag | null;
  sku: {
    id: number;
    name: string;
    icon: ServiceImage;
  };
  tech: Tech;
  status: StatusesService;
  product_serial_number: string | null;
  completed_at?: string | null;
};

export type FlagServiceResponse = {
  data: {
    service: ServiceDetails;
    status: string;
  };
};

export type UnflagServiceResponse = {
  data: {
    service: ServiceDetails;
    status: string;
  };
};

export type ServiceDetailsResponse = {
  data: {
    service: ServiceDetails | null;
  };
  error?: any;
};

export type ServicesState = {
  currentService: ServiceDetails | null;
};

export type ServiceImage = {
  medium?: string;
  retina?: string;
  retina_thumb?: string;
  thumb?: string;
};
