type PartialEventFN = (fn: (...args: any[]) => any) => (event: MouseEvent) => void;

export const objectNoop = () => ({});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (...args: any[]) => {};
export const prevent = (event: MouseEvent) => event.preventDefault();
export const prevented: PartialEventFN = fn => event => {
  event.preventDefault();
  fn();
};
export const stopImmediatePropagation: PartialEventFN = fn => event => {
  if (event.stopPropagation) event.stopPropagation();
  fn(event);
};
