import {post, get, destroy} from '@integrations/axios';

const userAPIs = {
  signIn: post('rails', '/service/auth/sessions'),
  getUserInfo: get('rails', '/api/user/current'),
  signOut: destroy('rails', '/logout.json'),
  resetPassword: post('rails', '/api/clients/users/reset_password'),
  createPassword: post('rails', '/api/clients/users/create_password'),
};

export default userAPIs;
