import React, {useEffect, useState, useRef, ChangeEvent, MutableRefObject} from 'react';
import {useRouter} from 'next/router';
// import {Icon, InputField} from 'ht-styleguide';
import {Icon} from 'ht-styleguide';
import cn from 'classnames';
import debounce from 'lodash/debounce';

/* Ducks */
import {useAppSelector, useAppDispatch} from '@store/store';
import {jobsDuck} from '@features/Jobs/Jobs.ducks';

/* Components */
import ProgressHorizontal from '@features/Projects/Parts/Project.progresshorizontal';
import {MenuPanel, MenuListItem, MenuListItemCalloutGroup, MenuUnorderedList} from '@components/UI/Menu';
import Permission from '@components/Permission';
import TouchTarget from '@components/UI/TouchTarget';
import SearchInput from '@components/UI/SearchInput';

/* Utils */
import {noop} from '@utils/event';
import {projectHomePath} from '@constants/constants.paths';
import {updateRouteByModalRequest} from '@utils/route';

/* Styles */
import styles from '@features/Projects/Parts/Header/header.module.scss';

/* Types */
import {ProjectFull, ModalNames} from '@features/Projects/projects.types';
import {SearchTypes} from '@features/Jobs/jobs.types';
import useOutsideClick from '@hooks/useOutsideClick';
import {PermissionRoles} from '@components/Permission/permission.types';

type HeaderDetailsProps = {
  project: ProjectFull;
};

type CustomEvent<T> = ChangeEvent<T> & MutableRefObject<T>;

const MIN_CHARACTER_SEARCH = 1;
export const openMenuIconTestId = 'ProjectDetailHeader-OpenMenuIcon';
const HeaderDetails = ({project}: HeaderDetailsProps) => {
  /* Hooks */
  const router = useRouter();
  const dispatch = useAppDispatch();
  const searchType = useAppSelector(jobsDuck.selectors.getSearchType);
  const isFlagged = useAppSelector(jobsDuck.selectors.getFlagged);
  const searchFilterLength = useAppSelector(jobsDuck.selectors.getSearchFilterLength);
  const menuRef: React.RefObject<HTMLDivElement> = useRef(null);

  /* constants */
  const {name} = project;
  const isKeywordSearch = searchType === SearchTypes.keyword;

  /* ref */
  const inputRef = useRef(null);

  /* Local State */
  const [classAdjustments, setClassAdjustments] = useState({name: 'name', ht: 'height-normal'});
  const [hide, setHide] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  /* Classes */
  const flagStatusClass = cn(styles.filter_icon, {
    [styles.filter_icon_active]: isFlagged,
  });

  const filterStatusClass = cn(styles.filter_icon, {
    [styles.filter_icon_active]: searchFilterLength > 0,
  });

  /* Methods */
  const toggleMenu = () => setShowMenu(!showMenu);
  const onHandleSearchInput = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;
      await dispatch(jobsDuck.actions.setSearchTerm(value));

      if (value.length >= MIN_CHARACTER_SEARCH) {
        await dispatch(jobsDuck.actions.search());
      }
    },
    1000,
    {trailing: true, leading: false}
  );

  // ref not working for all event actions, only some.: React.MutableRefObject<any>
  const onHandleInputInteraction = (e: CustomEvent<HTMLInputElement>, type: SearchTypes) => {
    dispatch(jobsDuck.actions.setSearchType(type));

    if (e && e.current) {
      e.current.value = '';
    }

    if (e && e.target) {
      e.target.value = '';
    }
  };

  const inputBehavior = isKeywordSearch ? {icon: 'v2-close-icon', action: onHandleInputInteraction} : {icon: '', action: noop};

  const handleScroll = () => {
    if (window.scrollY > 40) {
      setClassAdjustments({name: 'name-sm', ht: 'height_reduced'});
      setHide(true);
    } else {
      setClassAdjustments({name: 'name', ht: 'height_normal'});
      setHide(false);
    }
  };

  /* Xor behavior for setFlagged Search */
  const filterByFlaggedSearch = async () => {
    await dispatch(jobsDuck.actions.setFlagged(!isFlagged));
    await dispatch(jobsDuck.actions.search());
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useOutsideClick(menuRef, () => (showMenu ? toggleMenu() : null), [showMenu]);

  return (
    <>
      <header className={cn(styles.container, styles[classAdjustments.ht], 'box-shadow-bottom-small paddingY-small')}>
        <div className={styles.container_navigation}>
          <div data-testid="header-details-back">
            <TouchTarget onClick={() => router.push(projectHomePath())}>
              <Icon dataTestId="back-arrow" className="icon-large" name="Arrow" />
            </TouchTarget>
          </div>
          <TouchTarget onClick={toggleMenu}>
            <div ref={menuRef}>
              <Icon name="More" className={styles.icon_more} dataTestId={openMenuIconTestId} />
            </div>
          </TouchTarget>
        </div>

        <div className={cn(styles[classAdjustments.name], 'paddingTop-small font-weight-bold')}>{name}</div>
        {!hide && (
          <div className="paddingTop-tiny">
            <ProgressHorizontal
              ut={project.units_total}
              uut={project.unserviceable_units_total}
              cut={project.completed_units_total}
              cup={project.completed_units_percent}
              oup={project.open_units_percent}
              uup={project.unserviceable_units_percent}
            />
          </div>
        )}
        <div className="paddingTop-small">
          <div className={styles.filter_search}>
            {!isKeywordSearch && (
              <div className="flex flexDirectionRow">
                <div data-testid="flag-jobs" onClick={filterByFlaggedSearch} className={flagStatusClass}>
                  <Icon name="flag-outline" />
                </div>
                <div data-testid="filter-jobs" onClick={() => updateRouteByModalRequest(ModalNames.FILTER)} className={filterStatusClass}>
                  <Icon name="Filter" />
                </div>
              </div>
            )}
            <SearchInput
              inputRef={inputRef}
              onInputChange={onHandleSearchInput}
              onInputFocus={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                // @ts-ignore
                onHandleInputInteraction(e, SearchTypes.keyword);
              }}
              inputIconName={inputBehavior.icon}
              onInputIconClick={e => inputBehavior.action(e, SearchTypes.status)}
            />
          </div>
        </div>
      </header>
      {showMenu && (
        <MenuPanel>
          <MenuUnorderedList>
            <MenuListItem dataTestId="menu-project-details" icon="Info-outline" onClick={() => updateRouteByModalRequest(ModalNames.PROJECT_INFO)}>
              Project Details
            </MenuListItem>
            <Permission>
              <MenuListItem dataTestId="menu-manage-team" icon="Team" onClick={() => updateRouteByModalRequest(ModalNames.LIST_TEAM)}>
                Manage Team
              </MenuListItem>
            </Permission>
            <Permission level={PermissionRoles.billing_lead}>
              <MenuListItem dataTestId="menu-payment-details" icon="Money" onClick={() => updateRouteByModalRequest(ModalNames.PAYMENT_DETAILS)}>
                Payment Details
              </MenuListItem>
            </Permission>
            <MenuListItemCalloutGroup>
              <MenuListItem dataTestId="menu-create-job" icon="Plus-Circle" onClick={() => updateRouteByModalRequest(ModalNames.CREATE_JOB)}>
                Create a New Job
              </MenuListItem>
            </MenuListItemCalloutGroup>
          </MenuUnorderedList>
        </MenuPanel>
      )}
    </>
  );
};

export default HeaderDetails;
