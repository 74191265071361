export const swipeButtonDataTestId = 'SwipeButton';

export const ICON_NAMES = {
  SUCCESS: 'Check-Round-Fill',
  ALERT: 'Trash',
  CANCEL: 'Unserviceable',
  FLAGGED: 'flag-filled',
  UNFLAGGED: 'flag-x',
  UNCANCEL: 'undo',
};

export const ICON_LABELS = {
  SUCCESS: 'Approve',
  COMPLETE_JOB: 'Complete',
  ALERT: 'Delete',
  CANCEL: 'Unserviceable',
  FLAGGED: 'Flag',
  UNFLAGGED: 'Unflag',
  UNCANCEL: 'Reopen',
};
