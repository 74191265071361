import {IHash} from '@features/Application/application.types';
import getValuesByKey from '@utils/object/getValuesByKey';
import {isValidObjectWithKeys} from '@utils/object/isValidObjectWithKeys';
import {ERROR_MESSAGES, FAILURE_CONFIG, FailureState} from '@store/middleware/notifications';
import Router from 'next/router';

/*
 * Seems every error is wrapped in a special key which is fine in some respects.
 * But for this app, its tedious and unwarrented. So, we will unwrap it, "if" it has
 * this key.
 *
 * recursive
 * */

type CustomError = IHash<any>;
const unWrapError = (data: CustomError): string => {
  const errors = getValuesByKey(data, ['errors'])?.[0] || '';

  return (function iter(e): string {
    if (!e || typeof e === 'string') return e;

    if (Array.isArray(e)) {
      return e[0];
    }

    if (isValidObjectWithKeys(e)) {
      return Object.keys(e).reduce((accum, k) => iter(e[k]) + accum, '');
    }

    return '';
  })(errors);
};

export default unWrapError;

export const tryToDeriveStringError = (error: string): [string, {errorMessage: string}] => {
  if (error && typeof error === 'string' && !error.match(FAILURE_CONFIG[FailureState.REJECTED].matcher as RegExp)) {
    return [FailureState.CUSTOM, {errorMessage: `${Router.asPath} : ${error}`}];
  }

  return [FailureState.DEFAULT, {errorMessage: ERROR_MESSAGES.DEFAULT}];
};
