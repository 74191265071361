import React from 'react';
import {Children} from '@features/Application/application.types';

type MenuLineItemCalloutGroupProps = {
  children: Children;
  className?: string;
};

const MenuUL = ({children, className = ''}: MenuLineItemCalloutGroupProps) => <ul className={className}>{children}</ul>;

export default MenuUL;
