import {AnyAction, createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {applicationDuck} from '@features/Application/Application.ducks';
import router from 'next/router';
import omit from 'lodash/omit';

import * as TS from '@features/Projects/projects.types';
import {ErrorResponse} from '@features/Application/application.types';
import projectAPIs from '@features/Projects/projects.api';
import {RootState} from '@store/store';
import {htToast} from 'ht-styleguide';
import {autoCloseToast} from '@constants/constants.base';
import {BASE_PROJECT_SEARCH_FILTERS} from './projects.constants';

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/

export const PROJECT_INITIAL_STATE: TS.ProjectsState = {
  project: {} as TS.ProjectFull,
  projects: [],
  projectsByType: {
    [TS.SearchTypes.keyword]: {
      projects: [],
    },
    [TS.SearchTypes.status]: {
      projects: [],
    },
  },
  searchTerm: '',
  searchType: TS.SearchTypes.status,
  searchFilters: {...BASE_PROJECT_SEARCH_FILTERS.IN_PROGRESS},
};

export const initialState = PROJECT_INITIAL_STATE;

/*
 ************ ACTIONS
 */
export const asyncActions = {
  getAllProjects: createAsyncThunk<TS.ProjectListResponsePayload, void, {rejectValue: ErrorResponse; getState: RootState}>('project/getAllProjects', async (_, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));
    const projectResponse = await projectAPIs.getAllProjects();
    dispatch(applicationDuck.actions.setLoading(false));

    if (projectResponse.err) {
      return rejectWithValue(projectResponse as ErrorResponse);
    }

    return projectResponse;
  }),
  getProjectsByType: createAsyncThunk<TS.ProjectListResponsePayload, void, {rejectValue: ErrorResponse; getState: RootState}>(
    'project/getProjectsByType',
    async (_, {rejectWithValue, dispatch, getState}) => {
      const {projects} = getState() as RootState;
      const {searchTerm, searchFilters} = projects;

      let paramsObj: {statuses?: TS.StatusesProjectFilter[]; paused?: TS.PausedStatusTypes; term?: string} = {};

      if (searchTerm) {
        // If search term is provided override the searchFilter to get all statuses
        paramsObj.term = searchTerm;
        paramsObj.statuses = []; // Empty array returns completed and not_completed
      } else {
        paramsObj = searchFilters;
      }

      dispatch(applicationDuck.actions.setLoading(true));
      const projectResponse = await projectAPIs.getAllProjects(null, null, {params: paramsObj});
      dispatch(applicationDuck.actions.setLoading(false));

      if (projectResponse.err) {
        return rejectWithValue(projectResponse as ErrorResponse);
      }

      return projectResponse;
    }
  ),
  getProjectById: createAsyncThunk<TS.ProjectFullResponsePayload, void, {rejectValue: ErrorResponse; getState: RootState}>('project/getProjectById', async (_, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));
    const {pid} = router.query;
    const projectResponse = await projectAPIs.getProjectById({pid: pid as string});

    dispatch(applicationDuck.actions.setLoading(false));

    if (projectResponse.err) {
      return rejectWithValue(projectResponse as ErrorResponse);
    }

    return projectResponse;
  }),
  addTechToProject: createAsyncThunk<TS.TechsResponsePayload, {id: (string | number)[]}, {rejectValue: ErrorResponse}>('project/addTechToProject', async ({id}, {rejectWithValue}) => {
    const {pid} = router.query;
    const projectResponse = await projectAPIs.addTechToProject({pid: pid as string}, {tech_ids: id});

    if (projectResponse.err) {
      return rejectWithValue(projectResponse as ErrorResponse);
    }
    // @ts-ignore
    htToast.success('Techs added to the project!', {autoClose: autoCloseToast});

    return projectResponse;
  }),
  removeTechFromProject: createAsyncThunk<TS.TechsResponsePayload, {id: string}, {rejectValue: ErrorResponse}>('project/removeTechFromProject', async ({id}, {rejectWithValue}) => {
    const {pid} = router.query;
    const projectResponse = await projectAPIs.removeTechFromProject({tech_id: id, pid: pid as string});

    if (projectResponse.err) {
      return rejectWithValue(projectResponse as ErrorResponse);
    }

    // @ts-ignore
    htToast.success('Tech removed from project', {autoClose: autoCloseToast});

    return projectResponse;
  }),
  getAllTechsInProject: createAsyncThunk<TS.TechsResponsePayload, {pid: string}, {rejectValue: ErrorResponse}>('project/getAllTechsInProject', async ({pid}, {rejectWithValue}) => {
    const projectResponse = await projectAPIs.getAllTechsInProject({pid});

    if (projectResponse.err) {
      return rejectWithValue(projectResponse as ErrorResponse);
    }

    return projectResponse;
  }),
  getPaymentDetails: createAsyncThunk<TS.PaymentDetailsPayload, {breakdown: boolean}, {rejectValue: ErrorResponse; getState: RootState}>(
    'project/getPaymentDetails',
    async ({breakdown = true}, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));
      const {pid} = router.query;
      const paymentResponse = await projectAPIs.getPaymentDetails({pid: pid as string}, {breakdown});
      dispatch(applicationDuck.actions.setLoading(false));

      if (paymentResponse.err) {
        return rejectWithValue(paymentResponse as ErrorResponse);
      }
      return paymentResponse;
    }
  ),
};

/*
*******************************************************
  SLICE
*******************************************************
*/
const projectSlice = createSlice({
  name: 'projectState',
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
      state.searchType = TS.SearchTypes.keyword;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;

      // IF type is status, keyword can't exist.
      if (action.payload === TS.SearchTypes.status) {
        state.searchTerm = '';
        state.projectsByType[TS.SearchTypes.keyword].projects = [];
      }
    },
    setSearchFilters: (state, action: PayloadAction<TS.ProjectsState['searchFilters']>) => {
      state.searchFilters = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getAllProjects.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.projects = data.projects;
      })
      .addCase(asyncActions.getProjectsByType.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.projectsByType[state.searchType].projects = data.projects as [];
      })
      .addCase(asyncActions.getProjectById.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.project = {
          ...state.project,
          ...data.project,
        };
      })
      .addCase(asyncActions.getPaymentDetails.fulfilled, (state, action) => {
        const {
          data: {result},
        } = action.payload;
        state.project = {
          ...state.project,
          paymentDetails: omit(result, 'project'),
        };
      })
      .addMatcher(
        (action): action is AnyAction =>
          [asyncActions.addTechToProject.fulfilled, asyncActions.getAllTechsInProject.fulfilled, asyncActions.removeTechFromProject.fulfilled].some(actionCreator => actionCreator.match(action)),
        (state, action: AnyAction) => {
          state.project.techs = action.payload.data.techs;
        }
      );

    // .addCase(HYDRATE, (state, action: any) => {})
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getProjectsState = (state: RootState): TS.ProjectsState => state?.projects ?? initialState;
/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const selectors = {
  getProjectsState: createSelector(getProjectsState, projects => projects),
  getAllProjects: createSelector(getProjectsState, projects => projects.projects),
  getAllProjectsByType: createSelector(getProjectsState, projects => projects.projectsByType),
  getTechById: (id: number | string) => createSelector(getProjectsState, projects => projects.project?.techs?.find((tech: TS.Tech) => +tech.id === +id)),
  getCurrentProject: (id: string) => createSelector(getProjectsState, ({project}) => (+id === +project?.id ? project : ({} as TS.ProjectFull))),
  getCurrentProjectByKey: (key: string) => createSelector(getProjectsState, projects => projects.project[key]),
  getProjectsByKey: (key: keyof TS.ProjectsState) => createSelector(getProjectsState, projects => projects && projects[key]),
  getSearchType: createSelector(getProjectsState, projects => projects.searchType),
  getSearchTerm: createSelector(getProjectsState, projects => projects.searchTerm),
  getSearchFilters: createSelector(getProjectsState, projects => projects.searchFilters),
};

export const projectsDuck = {
  actions: {...projectSlice.actions, ...asyncActions},
  reducer: projectSlice.reducer,
  selectors,
};
