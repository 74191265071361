import Router from 'next/router';
import {authToken} from '@utils/auth';
import {Middleware} from 'redux';

/* Utils */
import isRequestedStatus from '@utils/request/isRequestedStatus';

/* Constants */
import {PENDING, REJECTED} from '@constants/constants.base';
import {homePath} from '@constants/constants.paths';
import {isBrowser} from '@utils/env';

const AUTH_TARGET_ACTIONS = [PENDING, REJECTED];

const authenticationMiddleware: Middleware = (/* api */) => next => action => {
  const apiGuardedRequest = AUTH_TARGET_ACTIONS.some(target => action?.type?.endsWith(target));
  const forbidden = !authToken.isValidJWT();
  let isUnauthorized = false;

  if (action?.type?.endsWith('/rejected')) {
    isUnauthorized = isRequestedStatus(action, [401]);
  }

  if ((forbidden && apiGuardedRequest) || isUnauthorized) {
    // api.dispatch(userDuck.actions.signOut());
    if (isBrowser()) {
      Router.push(homePath());
    }
  }

  return next(action);
};

export default authenticationMiddleware;
