import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

function MduBugsnag() {
  const key = process.env.NEXT_PUBLIC_BUGSNAG_KEY;

  Bugsnag.start({
    apiKey: key!,
    releaseStage: process.env.NEXT_PUBLIC_NODE_ENV || process.env.NODE_ENV,
    enabledReleaseStages: ['production', 'staging'],
    maxBreadcrumbs: 10,
    plugins: [new BugsnagPluginReact()],
  });

  return Bugsnag.getPlugin('react')!.createErrorBoundary(React);
}

export default MduBugsnag;
