import cn from 'classnames';

type ProgressHorizontalProps = {
  cup: number; // Completed Units percentage
  oup: number; // Open units percentage
  uup: number; // Unserviceable units percentage
  cut: number; // Completed units total
  uut: number; // Unserviceable units total
  ut: number; // Units total
};

const ProgressHorizontal = ({ut = 0, cut = 0, cup = 0, oup = 0, uup = 0, uut = 0}: ProgressHorizontalProps) => {
  const completedPercentageStyles = cn('completed_percentage', {
    border_left: cup > 0,
    border_right: cup > 0 && !uup,
  });
  const unserviceablePercentageStyles = cn('unserviceable_percentage', {
    border_left: uup > 0 && !cup,
    border_right: uup > 0,
  });
  return (
    <div>
      <div className="titles">
        <div>
          {cut + uut}/{ut}
        </div>
        <div>{cup + uup}% COMPLETE</div>
      </div>
      <div className="graph border_left border_right">
        <div className={completedPercentageStyles} />
        <div className={unserviceablePercentageStyles} />
        <div className="open_percentage" />
      </div>
      <style jsx>{`
        .titles {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: #6d7d8c;
          font-size: 11px;
          line-height: 16px;
          line-spacing: 0.1px;
        }
        .graph {
          display: flex;
          flex-direction: row;
          background-color: #c4c4c4;
          overflow: hidden;
        }
        .completed_percentage {
          width: ${cup}%;
          background-color: #00c9bc;
          height: 6px;
        }
        .unserviceable_percentage {
          width: ${uup}%;
          background-color: #e64f49;
          height: 6px;
        }
        .open_percentage {
          width: ${oup}%;
          background-color: #c4c4c4;
          height: 6px;
        }
        .border_left {
          border-top-left-radius: 360px;
          border-bottom-left-radius: 360px;
        }
        .border_right {
          border-top-right-radius: 360px;
          border-bottom-right-radius: 360px;
        }
      `}</style>
    </div>
  );
};

export default ProgressHorizontal;
