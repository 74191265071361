import React from 'react';
import styles from './card.module.scss';

type CardSkuProps = {
  name: string;
};

const CardSku = ({name}: CardSkuProps) => <div className={styles.container_services}>{name}</div>;

export default CardSku;
