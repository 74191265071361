type Pluralize = {
  text: string;
  count: number | string;
  suffix?: string;
  withCount?: boolean;
};
// eslint-disable-next-line import/prefer-default-export
export const pluralize = ({text, count, suffix = 's', withCount = true}: Pluralize) => {
  const postfix = +count > 1 ? suffix : '';
  const withCountValue = withCount ? `${count} ` : '';

  if (typeof text !== 'string') return '';
  if (+count === 0 || Number.isNaN(+count)) return `0 ${text}${suffix}`;

  return `${withCountValue}${text}${postfix}`;
};
