import {StatusesJob} from '@features/Jobs/jobs.types';
import {ProjectsState, StatusesProjectFilter, PausedStatusTypes} from './projects.types';

type ProjectUnitStatus = {
  [key in keyof typeof StatusesJob]: {
    id: number;
    name: string;
  };
};

// eslint-disable-next-line import/prefer-default-export
export const PROJECT_FILTER_UNIT_STATUS: Omit<ProjectUnitStatus, 'flagged'> = {
  cancelled: {
    id: 1,
    name: 'Cancelled',
  },
  unserviceable: {
    id: 2,
    name: 'Unserviceable',
  },
  completed: {
    id: 3,
    name: 'Completed',
  },
  not_started: {
    id: 4,
    name: 'Not Started',
  },
  pending_approval: {
    id: 5,
    name: 'Pending Approval',
  },
  in_progress: {
    id: 6,
    name: 'In Progress',
  },
};

export const PAYMENT_FILTER_STATUS: {[k: string]: {id: number; name: 'Paid' | 'Not Paid'}} = {
  paid: {
    id: 1,
    name: 'Paid',
  },
  not_paid: {
    id: 2,
    name: 'Not Paid',
  },
};

export const BASE_PROJECT_SEARCH_FILTERS: {[tab in 'IN_PROGRESS' | 'PAUSED' | 'COMPLETED']: ProjectsState['searchFilters']} = {
  IN_PROGRESS: {
    statuses: [StatusesProjectFilter.not_completed],
    paused: PausedStatusTypes.ONLY_NOT_PAUSED,
  },
  PAUSED: {
    statuses: [], // endpoint will return completed/non-completed projects if it's an empty array
    paused: PausedStatusTypes.ONLY_PAUSED,
  },
  COMPLETED: {
    statuses: [StatusesProjectFilter.completed],
    paused: PausedStatusTypes.ONLY_NOT_PAUSED,
  },
};
