import React from 'react';
import {ProjectFull} from '@features/Projects/projects.types';
import Link from 'next/link';
import dayjs from 'dayjs';
/* Utils */
import {pluralize} from '@utils/formatter/pluralize';
/* Components */
import Progress from '@features/Projects/Parts/Project.progress';
import {CardJob} from '@components/UI/Card';
/* Constants */
import {shortMonthWithDay} from '@constants/constants.base';

function getProgressComponent(completed_units_percent: number) {
  return (
    <Progress
      style={{margin: 0, width: 40}}
      progress={completed_units_percent}
      reduction={0}
      strokeWidth={23}
      ballStrokeWidth={0}
      gradient={[
        {stop: 0.0, color: '#0992e9'},
        {stop: 0.5, color: '#0992e9'},
        {stop: 1, color: '#00cdbe'},
      ]}
    />
  );
}

/**
 * Gives us the display for the start date of the project
 *
 * @param {ProjectFull} project
 * @returns {string}
 */
const determineStartDisplay = (project: ProjectFull) => {
  const startDate = project.start_date;

  if (!startDate) return '';

  const formattedStartDate = dayjs(project.start_date).format(shortMonthWithDay);
  const startText = dayjs().isAfter(dayjs(project.start_date)) ? 'Started' : 'Starts';

  return ` | ${startText} ${formattedStartDate}`;
};

const getMappedProjects = (projects: ProjectFull[]) =>
  projects.map(project => {
    const Component = getProgressComponent(project.completed_units_percent);
    const info = `${pluralize({text: 'unit', count: project.units_total})} ${determineStartDisplay(project)}`;

    return (
      <div key={`${project.id}-${project.name}`} className="marginBottom-tiny1">
        <Link href={`/project/${project.id}`} legacyBehavior>
          <a>
            <CardJob RightComponent={Component} name={project.name} info={info} />
          </a>
        </Link>
      </div>
    );
  });

type ProjectViewProps = {projects: ProjectFull[]};

const ByStatus = ({projects}: ProjectViewProps) => {
  if (!projects.length) return null;
  return <div>{getMappedProjects(projects)}</div>;
};

const ByKeyword = ({projects}: ProjectViewProps) => {
  /*
    The API returns one list of all keyword matching projects regardless of completion/paused status.
    Separate the list into three categories: completed, not completed, and paused.
  */
  if (!projects.length) return null;

  const {completed, notCompleted, paused} = projects.reduce(
    (acc, elem) => {
      if (elem.paused) {
        acc.paused.push(elem);
      } else if (elem.status === 'approved') {
        acc.completed.push(elem);
      } else {
        acc.notCompleted.push(elem);
      }
      return acc;
    },
    {completed: [] as ProjectFull[], notCompleted: [] as ProjectFull[], paused: [] as ProjectFull[]}
  );
  return (
    <div>
      {Boolean(notCompleted.length) && (
        <div className="marginBottom-medium">
          <p className="h4 marginBottom-medium">In Progress</p>
          {getMappedProjects(notCompleted)}
        </div>
      )}
      {Boolean(paused.length) && (
        <div className="marginBottom-medium">
          <p className="h4 marginBottom-medium">Paused</p>
          {getMappedProjects(paused)}
        </div>
      )}
      {Boolean(completed.length) && (
        <>
          <p className="h4 marginBottom-medium">Complete</p>
          {getMappedProjects(completed)}
        </>
      )}
    </div>
  );
};

const ProjectView = {ByStatus, ByKeyword};

export default ProjectView;
