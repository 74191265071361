import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import {useForm} from 'react-hook-form';
import {Modal, ELEMENT_SIZE, InputField, Button, BUTTON_THEMES} from 'ht-styleguide';

/* Ducks & Store */
import {useAppDispatch} from '@store/store';
import {jobsDuck} from '@features/Jobs/Jobs.ducks';

/* Components */
import {GroupSelectionInfo} from '@features/Jobs/Modals/Jobs.components';

/* Utils */
import {logOutSideError} from '@utils/request/logOutsideError';

/* Constants */
import {FieldErrorEmpty} from '@constants/constants.base';

/* Types */
import {ProjectFull, ProjectGroup} from '@features/Projects/projects.types';

/* Styles */
import styles from '@styles/modals.module.scss';

const CreateConfirmModalDynamic = dynamic(() => import('@features/Jobs/Modals/Jobs.create-confirm'), {ssr: false});

type CreateJobModalProps = {
  isVisible: boolean;
  project: ProjectFull;
  type: INIT_TYPES;
};

type FormValues = {
  unit_name: string;
};

const BLANK: ProjectGroup = {
  id: 0,
  name: 'Start from Scratch',
  project_services: [
    {
      id: 0,
      quantity: 0,
      sku: {
        id: 0,
        name: "You'll add services after the job is created",
      },
    },
  ],
};

export enum INIT_TYPES {
  create = 'create',
  start = 'start',
}

const TEXT_BY_TYPE = {
  [INIT_TYPES.create]: {
    header: 'Create a Job',
    subHeader: 'Tell us about the job you are adding to this project.',
    cta: 'Create Job',
  },
  [INIT_TYPES.start]: {
    header: 'Start a Job',
    subHeader: 'Tell us about the unit you are starting work on.',
    cta: 'Start Job',
  },
};

const CreateJobModal = ({isVisible, project, type}: CreateJobModalProps) => {
  /* local state */
  const [selectedGroup, setSelectedGroup] = useState<number>();
  const [confirmModal, setConfirmModal] = useState<Boolean>(false);

  /* hooks */
  const dispatch = useAppDispatch();
  const router = useRouter();

  const {
    register,
    handleSubmit,
    setError,
    formState: {errors, isDirty, isValid},
  } = useForm<FormValues>({mode: 'onChange'});

  /* constants */
  const textDisplay = TEXT_BY_TYPE[type] || {};
  const isStartType = INIT_TYPES.start === type;
  const {project_groups = []} = project;
  const filteredGroups = project_groups.filter(pg => pg.project_services.length);
  const projectGroupsSelection = isStartType ? filteredGroups : [BLANK].concat(filteredGroups);

  /* methods */
  const closeModal = () => router.back();
  const closeConfirmModal = () => setConfirmModal(false);

  /* Submit is good, lets create the job */
  const onSubmit = async (attributes: FormValues) => {
    const params = {
      project_group_id: selectedGroup,
      unit_name: attributes.unit_name,
    };
    /* Remove invalid project group */
    if (!params.project_group_id) delete params.project_group_id;

    const response = await dispatch(jobsDuck.actions[`${type}Job`](params));
    const unitId = response.payload?.data?.unit?.id;
    const unitNameError = response.payload?.data?.errors?.unit_name;

    if (!unitId) {
      logOutSideError({err: `No unit id returned from job creation - ${router.asPath}`});
    }

    if (unitNameError) {
      return setError('unit_name', {type: 'manual', message: unitNameError});
    }

    return unitId ? router.push(`${window.location.pathname}/job/${unitId}`) : router.push(window.location.pathname);
  };

  const onHandleGroupSelection = (group: ProjectGroup) => () => {
    setSelectedGroup(group.id);
  };

  useEffect(() => {
    if (type === INIT_TYPES.create) {
      setConfirmModal(true);
    }
  }, [type]);

  return (
    <>
      <Modal
        isVisible={isVisible}
        modalClassName={styles.container}
        elementSize={ELEMENT_SIZE.LARGE}
        header={textDisplay.header}
        subheader={textDisplay.subHeader}
        subheaderClasses={styles.subheader_large}
        hide={closeModal}
        onRequestClose={closeModal}
        footerElement1={
          <Button disabled={!isDirty || !isValid || (isStartType && !selectedGroup)} theme={BUTTON_THEMES.PRIMARY} className={styles.ctaButton} onClick={handleSubmit(onSubmit)}>
            {textDisplay.cta}
          </Button>
        }
      >
        <div className="container">
          <div className="border_bottom marginBottom-medium paddingBottom-medium">
            <div className="flex flexDirectionColumn">
              <div className="paddingBottom-tiny1">
                <InputField error={errors.unit_name && (errors.unit_name.message || FieldErrorEmpty)} label="Unit Name" {...register('unit_name', {required: true})} />
              </div>
              <div className="paddingTop-small n700">Select Job Template</div>
              <div data-testid="project-group-selection-display">
                {projectGroupsSelection.map(group => {
                  const selected = selectedGroup === group.id;
                  return (
                    <div onClick={onHandleGroupSelection(group)} key={group.id}>
                      <GroupSelectionInfo allowHover selected={selected} title={group.name} services={group.project_services} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {confirmModal && <CreateConfirmModalDynamic closeModal={closeConfirmModal} isVisible={Boolean(confirmModal)} />}
    </>
  );
};

export default CreateJobModal;
